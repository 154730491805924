import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { useSpinDelay } from 'spin-delay'

import { Dialog, DialogCloseButton } from '@fysioscout/ui/overlays/dialog'
import { Callout } from '@fysioscout/ui/status/callout'
import { Spinner } from '@fysioscout/ui/status/spinner'
import { Text } from '@fysioscout/ui/typography/text'

import { clinicTreatmentQueries } from '@/api/fysioscout/endpoints/clinic-treatment/queries'
import { clinicVideoConsultationsQueries } from '@/api/fysioscout/endpoints/clinic-video-consultations/queries'

import { useEditConsultationContext } from '../context'
import { useConsultationId } from '../store'
import { EditConsultationForm } from './edit-consultation-form'

export function EditConsultationDialog() {
  const context = useEditConsultationContext()

  const consultationId = useConsultationId() ?? ''

  const consultation = useQuery(
    clinicVideoConsultationsQueries.byId({ path: { id: consultationId } }),
  )

  const treatments = useQuery(
    clinicTreatmentQueries.treatments(
      { path: { patientId: consultation.data?.patient.id ?? '' } },
      { enabled: Boolean(consultation.data?.patient.id) },
    ),
  )

  const isLoading = useSpinDelay(
    consultation.isLoading || treatments.isLoading || context.isLoading,
    {
      minDuration: 400,
      delay: 0,
    },
  )

  const isEmptyData = context.employees.length === 0 || consultation.data === undefined

  if (isLoading) {
    return (
      <Dialog
        className={'stack center text-subtle-foreground h-full gap-2'}
        aria-label={'Indlæser formular'}
      >
        <Spinner />
        <Text size={'2'}>Indlæser formular..</Text>
      </Dialog>
    )
  }

  if (isEmptyData) {
    return (
      <Dialog className={'stack center h-full'} aria-label={'Noget gik galt'}>
        <Callout type={'neutral'} size={'1'}>
          <Text>Noget gik galt. Prøv igen senere.</Text>
        </Callout>
      </Dialog>
    )
  }

  return (
    <Dialog
      data-testid={'edit-consultation-dialog'}
      aria-label={'Rediger videokonsultation'}
      className={'stack h-full outline-none'}
    >
      {({ close }) => (
        <>
          <DialogCloseButton onPress={close} />

          <div className={'mt-8'}>
            <EditConsultationForm
              employees={context.employees}
              consultation={consultation.data}
              treatments={treatments.data}
            />
          </div>
        </>
      )}
    </Dialog>
  )
}
