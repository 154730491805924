import React from 'react'

import { Cell } from '@fysioscout/ui/collections/table'
import { Text } from '@fysioscout/ui/typography/text'

import { useConsultationTableRowContext } from '@/features/video-consultation/contexts/consultation-table-row-context'

export function DurationCell() {
  const { consultation } = useConsultationTableRowContext()

  return (
    <Cell>
      {consultation.status === 'cancelled' ? (
        <Text color={'error'}>Ikke afholdt</Text>
      ) : (
        <Text subtle={!consultation.duration}>
          {consultation.duration ? `${consultation.duration} min.` : '–'}
        </Text>
      )}
    </Cell>
  )
}
