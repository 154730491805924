import React from 'react'

import { CancelConsultationContext } from '../context'
import { createCancelConsultationStore } from '../store'

interface CancelConsultationProviderProps {
  children?: React.ReactNode
}

export function CancelConsultationProvider({ children }: CancelConsultationProviderProps) {
  const [store] = React.useState(() => createCancelConsultationStore())

  return (
    <CancelConsultationContext.Provider value={{ store }}>
      {children}
    </CancelConsultationContext.Provider>
  )
}
