import { queryOptions } from '@tanstack/react-query'
import ms from 'ms'
import { deepSnakeKeys } from 'string-ts'

import type { DeepCamelKeys } from 'string-ts'
import type {
  GetClinicVideoConsultationOptions,
  GetClinicVideoConsultationTranscriptionOptions,
  GetClinicVideoConsultationsOptions,
  GetConsultationsByPatientIdOptions,
} from './models'

import { unwrap } from '@fysioscout/fysioscout-js/utils'

import { fysioscoutClient } from '@/api/fysioscout/client'

export const clinicVideoConsultationsQueries = {
  all: () => ['clinic-video-consultations'],
  lists: () => [...clinicVideoConsultationsQueries.all(), 'lists'],

  /**
   * List all video consultations for a clinic
   *
   * @param params - The params for the query
   * @returns The query option
   * @see {@link https://api-dev.fysioscout.com/api/documentation/redoc#tag/Clinic-Video-Consultations-or-Shared/paths/~1api~1clinics~1video-consultations/get}
   */
  list: (params: DeepCamelKeys<GetClinicVideoConsultationsOptions['params']>) => {
    return queryOptions({
      queryKey: [...clinicVideoConsultationsQueries.lists(), 'list', params],
      queryFn: ({ signal }) => {
        return unwrap(
          fysioscoutClient.GET('/api/clinics/video-consultations', {
            signal,
            params: deepSnakeKeys(params),
          }),
        )
      },
      refetchInterval: ms('30s'),
    })
  },

  /**
   * Get a single video consultation for a clinic
   *
   * @param params - The params for the query
   * @returns The query option
   * @see {@link https://api.fysioscout.com/api/documentation/redoc#tag/Clinic-Video-Consultations-or-Shared/paths/~1api~1clinics~1video-consultations~1%7Bid%7D/get}
   */
  byId: (params: DeepCamelKeys<GetClinicVideoConsultationOptions['params']>) => {
    return queryOptions({
      queryKey: [...clinicVideoConsultationsQueries.all(), 'byId', params],
      queryFn: ({ signal }) => {
        return unwrap(
          fysioscoutClient.GET('/api/clinics/video-consultations/{id}', {
            signal,
            params: deepSnakeKeys(params),
          }),
        )
      },
    })
  },

  /**
   * Get a single video consultation transcription for a clinic
   *
   * @param params - The params for the query
   * @returns The query option
   * @see {@link https://api.fysioscout.com/api/documentation/redoc#tag/Clinic-Video-Consultations-or-Shared/paths/~1api~1clinics~1video-consultations~1%7Bid%7D~1transcription/get}
   */
  transcription: (
    params: DeepCamelKeys<GetClinicVideoConsultationTranscriptionOptions['params']>,
  ) => {
    return queryOptions({
      queryKey: [...clinicVideoConsultationsQueries.all(), 'transcription', params],
      queryFn: ({ signal }) => {
        return unwrap(
          fysioscoutClient.GET('/api/clinics/video-consultations/{id}/transcription', {
            signal,
            params: deepSnakeKeys(params),
          }),
        )
      },
    })
  },

  /**
   * Get video consultations by patient id
   *
   * @param params - The params for the query
   * @returns The query option
   * @see {@link https://api.fysioscout.com/api/documentation/redoc#tag/Clinic-Video-Consultations-or-Shared/paths/~1api~1clinics~1video-consultations~1patients~1%7Bpatient_id%7D/get}
   */
  byPatientId: (params: DeepCamelKeys<GetConsultationsByPatientIdOptions['params']>) => {
    return queryOptions({
      queryKey: [...clinicVideoConsultationsQueries.all(), 'byPatientId', params],
      queryFn: ({ signal }) => {
        return unwrap(
          fysioscoutClient.GET('/api/clinics/video-consultations/patients/{id}', {
            signal,
            params: deepSnakeKeys(params),
          }),
        )
      },
      refetchInterval: ms('30s'),
    })
  },
}
