import { useEventListener } from 'ahooks'
import { match } from 'ts-pattern'

import { Button } from '@fysioscout/ui/buttons/button'
import { LogoMark } from '@fysioscout/ui/misc/logo-mark'
import { Separator } from '@fysioscout/ui/misc/separator'
import { Link } from '@fysioscout/ui/navigation/link'
import { Dialog, DialogCloseButton } from '@fysioscout/ui/overlays/dialog'
import { Drawer } from '@fysioscout/ui/overlays/drawer'
import { Heading } from '@fysioscout/ui/typography/heading'

import { AttachExistingPatientForm } from '@/features/patients/user-actions/create-patient/components/attach-existing-patient-form'
import { CreateNewPatientForm } from '@/features/patients/user-actions/create-patient/components/create-new-patient-form'
import { CreatePdfPatientForm } from '@/features/patients/user-actions/create-patient/components/create-pdf-patient-form'
import {
  useCreatePatientActions,
  useIsOpen,
  usePatientType,
} from '@/features/patients/user-actions/create-patient/store'

export function CreatePatientDrawer() {
  const actions = useCreatePatientActions()
  const isOpen = useIsOpen()

  const handleOpenChange = (isOpen: boolean) => {
    actions.onOpenChange(isOpen)

    if (!isOpen) {
      setTimeout(() => {
        actions.reset()
      }, 150)
    }
  }

  return (
    <Drawer isKeyboardDismissDisabled isOpen={isOpen} onOpenChange={handleOpenChange}>
      <CreatePatientDialog />
    </Drawer>
  )
}

function CreatePatientDialog() {
  const actions = useCreatePatientActions()
  const patientType = usePatientType()

  useEventListener('keyup', ({ key }) => {
    if (key !== 'Escape') return

    if (patientType) {
      actions.selectPatientType(null)
    } else {
      actions.onOpenChange(false)
    }
  })

  const closeDrawerAndReset = () => {
    actions.close()

    setTimeout(() => {
      actions.reset()
    }, 150)
  }

  const heading = match(patientType)
    .with('new', () => 'Opret ny patient')
    .with('existing', () => 'Tilknyt eksisterende patient')
    .with('pdf', () => 'Opret PDF-patient')
    .otherwise(() => 'Opret patient')

  const form = match(patientType)
    .with('new', () => <CreateNewPatientForm onSuccess={closeDrawerAndReset} />)
    .with('existing', () => <AttachExistingPatientForm onSuccess={closeDrawerAndReset} />)
    .with('pdf', () => <CreatePdfPatientForm onSuccess={closeDrawerAndReset} />)
    .otherwise(() => <PatientTypeButtonGroup />)

  return (
    <Dialog data-testid={'create-patient'} className={'stack h-full outline-none'}>
      {({ close }) => (
        <>
          <DialogCloseButton onPress={close} />

          <div className={'stack center h-full px-8'}>
            <div className={'stack center'}>
              <div className={'bg-accent-9 rounded-2xl p-4'}>
                <LogoMark className={'w-12 fill-white'} />
              </div>

              <Heading slot={'title'} size={'6'} className={'mt-4'}>
                {heading}
              </Heading>
            </div>

            <div className={'stack mt-10 w-full gap-8'}>
              {form}

              {patientType ? (
                <Link
                  intent={'back'}
                  className={'cursor-default'}
                  size={'sm'}
                  onPress={() => {
                    actions.selectPatientType(null)
                  }}
                >
                  <button>Tilbage</button>
                </Link>
              ) : null}
            </div>
          </div>
        </>
      )}
    </Dialog>
  )
}

function PatientTypeButtonGroup() {
  const createPatientActions = useCreatePatientActions()

  return (
    <div className={'stack gap-3'}>
      <Button
        onPress={() => {
          createPatientActions.selectPatientType('new')
        }}
      >
        Opret ny patient
      </Button>

      <Button
        variant={'soft'}
        color={'neutral'}
        onPress={() => {
          createPatientActions.selectPatientType('existing')
        }}
      >
        Tilknyt eksisterende patient
      </Button>

      <Separator />

      <Button
        variant={'outline'}
        color={'neutral'}
        onPress={() => {
          createPatientActions.selectPatientType('pdf')
        }}
      >
        Opret PDF-patient
      </Button>
    </div>
  )
}
