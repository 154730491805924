import React from 'react'
import { CopyIcon, LinkIcon } from 'lucide-react'
import { MenuTrigger } from 'react-aria-components'

import { Menu, MenuItem } from '@fysioscout/ui/collections/menu'
import { Cell, TableActionsTrigger } from '@fysioscout/ui/collections/table'
import { toast } from '@fysioscout/ui/status/toast'

import { useConsultationTableRowContext } from '@/features/video-consultation/contexts/consultation-table-row-context'

export function CopyLinksCell() {
  const { consultation } = useConsultationTableRowContext()

  const handleEmployeeCopy = async () => {
    await navigator.clipboard.writeText(consultation.host_room_url)
    toast.success('Behandler-link kopieret.')
  }

  const handlePatientCopy = async () => {
    await navigator.clipboard.writeText(consultation.viewer_room_url)
    toast.success('Patient-link kopieret.')
  }

  return (
    <Cell>
      <MenuTrigger>
        <TableActionsTrigger>
          <LinkIcon />
        </TableActionsTrigger>

        <Menu selectionMode={'none'} disallowEmptySelection placement={'bottom end'}>
          <MenuItem id={'copy-host-room-url'} onAction={handleEmployeeCopy} icon={<CopyIcon />}>
            Kopier behandler-link
          </MenuItem>

          <MenuItem id={'copy-viewer-room-url'} onAction={handlePatientCopy} icon={<CopyIcon />}>
            Kopier patient-link
          </MenuItem>
        </Menu>
      </MenuTrigger>
    </Cell>
  )
}
