import { deepSnakeKeys } from 'string-ts'

import type { DeepCamelKeys } from 'string-ts'
import type {
  CreateClinicVideoConsultationOptions,
  DeleteClinicVideoConsultationOptions,
  ResendConsultationEmailOptions,
  UpdateClinicVideoConsultationOptions,
} from './models'

import { unwrap } from '@fysioscout/fysioscout-js/utils'

import { fysioscoutClient } from '@/api/fysioscout/client'

export const clinicVideoConsultationMutations = {
  /**
   * Create a video consultation
   *
   * @param body - The request body.
   * @returns The mutation result
   * @see {@link https://api.fysioscout.com/api/documentation/redoc#tag/Clinic-Video-Consultations-or-Shared/paths/~1api~1clinics~1video-consultations/post}
   */
  create: (body: DeepCamelKeys<CreateClinicVideoConsultationOptions['body']>) => {
    return unwrap(
      fysioscoutClient.POST('/api/clinics/video-consultations', { body: deepSnakeKeys(body) }),
    )
  },

  /**
   * Update a video consultation
   *
   * @param opts - The options for the mutation.
   * @param opts.params - The params for the mutation.
   * @param opts.body - The request body.
   * @returns The mutation result
   * @see {@link https://api.fysioscout.com/api/documentation/redoc#tag/Clinic-Video-Consultations-or-Shared/paths/~1api~1clinics~1video-consultations~1%7Bid%7D/patch}
   */
  update: (opts: {
    params: DeepCamelKeys<UpdateClinicVideoConsultationOptions['params']>
    body: DeepCamelKeys<UpdateClinicVideoConsultationOptions['body']>
  }) => {
    return unwrap(
      fysioscoutClient.PATCH('/api/clinics/video-consultations/{id}', {
        params: deepSnakeKeys(opts.params),
        body: deepSnakeKeys(opts.body),
      }),
    )
  },

  /**
   * Delete a video consultation
   *
   * @param params - The params for the mutation.
   * @returns The mutation result
   * @see {@link https://api.fysioscout.com/api/documentation/redoc#tag/Clinic-Video-Consultations-or-Shared/paths/~1api~1clinics~1video-consultations~1%7Bid%7D/delete}
   */
  delete: (params: DeepCamelKeys<DeleteClinicVideoConsultationOptions['params']>) => {
    return unwrap(
      fysioscoutClient.DELETE('/api/clinics/video-consultations/{id}', {
        params: deepSnakeKeys(params),
      }),
    )
  },

  /**
   * Resend email for a video consultation
   *
   * @param params - The params for the mutation
   * @returns The mutation result
   * @see {@link https://api.fysioscout.com/api/documentation/redoc#tag/Clinic-Video-Consultations-or-Shared/paths/~1api~1clinics~1video-consultations~1%7Bconsultation_id%7D~1email~1resend/post}
   */
  resendConsultationEmail: (params: DeepCamelKeys<ResendConsultationEmailOptions['params']>) => {
    return unwrap(
      fysioscoutClient.POST('/api/clinics/video-consultations/{id}/email/resend', {
        params: deepSnakeKeys(params),
      }),
    )
  },
}
