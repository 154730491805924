import { CopyIcon, Link2Icon, SendIcon } from 'lucide-react'
import { MenuTrigger } from 'react-aria-components'
import { doNothing } from 'remeda'
import { match } from 'ts-pattern'

import type { Schemas } from '@fysioscout/fysioscout-js/type-helpers'
import type { Key } from 'react-aria-components'

import { Button } from '@fysioscout/ui/buttons/button'
import { Menu, MenuItem } from '@fysioscout/ui/collections/menu'
import { toast } from '@fysioscout/ui/status/toast'

import { useExportExercisesActions } from '@/features/patients/user-actions/export-exercises/store'
import { useCopyJournalUrl } from '@/features/treatments/api/copy-journal-url'

const actions = {
  exportExercises: 'export-exercises',
  copyLinkToJournal: 'copy-link-to-journal',
} as const

interface TreatmentShareMenuProps {
  treatment: Schemas['TreatmentDto']
}

export function TreatmentShareMenu({ treatment }: TreatmentShareMenuProps) {
  const copyJournalUrlMutation = useCopyJournalUrl()
  const exportExercisesActions = useExportExercisesActions()

  const handleExportExercises = () => {
    exportExercisesActions.setTreatment(treatment)

    setTimeout(() => {
      exportExercisesActions.open()
    }, 100)
  }

  const handleCopyLinkToJournal = () => {
    copyJournalUrlMutation.mutate(
      { path: { id: treatment.id } },
      {
        onSuccess: () => {
          toast.success(
            `Link til journal for ${treatment.name ? `"${treatment.name}"` : 'behandlingsforløbet'} blev kopieret.`,
          )
        },
      },
    )
  }

  const handleAction = (key: Key) => {
    match(key)
      .with(actions.exportExercises, handleExportExercises)
      .with(actions.copyLinkToJournal, handleCopyLinkToJournal)
      .otherwise(() => doNothing())
  }
  return (
    <MenuTrigger>
      <Button variant={'soft'} size={'md'} color={'neutral'} aria-label={'del'}>
        <Link2Icon />
      </Button>

      <Menu
        disallowEmptySelection
        onAction={handleAction}
        placement={'bottom end'}
        selectionMode={'none'}
      >
        <MenuItem id={actions.exportExercises} icon={<SendIcon />}>
          E-mail PDF-version
        </MenuItem>

        <MenuItem
          id={actions.copyLinkToJournal}
          icon={<CopyIcon />}
          isDisabled={copyJournalUrlMutation.isPending}
        >
          Kopier journal-link
        </MenuItem>
      </Menu>
    </MenuTrigger>
  )
}
