import React from 'react'

import { EditConsultationDrawer } from './edit-consultation-drawer'
import { EditConsultationProvider } from './edit-consultation-provider'

interface EditVideoConsultationProps {
  children?: React.ReactNode
}

export function EditVideoConsultation({ children }: EditVideoConsultationProps) {
  return (
    <EditConsultationProvider>
      <EditConsultationDrawer />

      {children}
    </EditConsultationProvider>
  )
}
