import { createFileRoute } from '@tanstack/react-router'
import { fallback, zodSearchValidator } from '@tanstack/router-zod-adapter'
import { z } from 'zod'

import { clinicPatientQueries } from '@/api/fysioscout/endpoints/clinic-patient/queries'

import { PatientsView } from './-patients-view'

const PatientsRouteSearchSchema = z.object({
  status: fallback(z.enum(['accepted', 'pending', 'rejected']), 'accepted').default('accepted'),
  query: fallback(z.string().optional(), undefined),
})

export const Route = createFileRoute('/_auth/patients/')({
  validateSearch: zodSearchValidator(PatientsRouteSearchSchema),
  loaderDeps: ({ search: { status } }) => ({ status }),
  loader: ({ context: { queryClient }, deps: { status } }) => {
    void queryClient.prefetchQuery(clinicPatientQueries.list('pending'))
    return queryClient.ensureQueryData(clinicPatientQueries.list(status))
  },
  component: PatientsView,
})
