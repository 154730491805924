import { useMutation, useQueryClient } from '@tanstack/react-query'

import { clinicVideoConsultationMutations } from '@/api/fysioscout/endpoints/clinic-video-consultations/mutations'
import { clinicVideoConsultationsQueries } from '@/api/fysioscout/endpoints/clinic-video-consultations/queries'
import { clinicQueries } from '@/api/fysioscout/endpoints/clinic/queries'

/**
 * Create a video consultation
 *
 * @example
 *   const { mutate, isLoading, isError } = useCreateVideoConsultation()
 *   mutate(createVideoConsultationOptions)
 *
 * @returns - The result of the mutation.
 */
export function useCreateConsultation() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: clinicVideoConsultationMutations.create,
    onSuccess: (_, variables) => {
      void queryClient.invalidateQueries({ queryKey: clinicQueries.getSubscription().queryKey })
      void queryClient.invalidateQueries({ queryKey: clinicVideoConsultationsQueries.lists() })
      void queryClient.invalidateQueries({
        queryKey: clinicVideoConsultationsQueries.byPatientId({ path: { id: variables.patientId } })
          .queryKey,
      })
    },
  })
}
