import { createStore, useStore } from 'zustand'

import type { Schemas } from '@fysioscout/fysioscout-js/type-helpers'

import { useEditConsultationContext } from './context'

type ConsultationId = Schemas['ConsultationResponseDto']['id']

export interface EditConsultationStore {
  isOpen: boolean
  consultationId: ConsultationId | null

  actions: {
    setIsOpen: (isOpen: boolean) => void
    setConsultationId: (consultationId: ConsultationId | null) => void

    reset: () => void
  }
}

export type InitialState = Omit<EditConsultationStore, 'actions'>

const INITIAL_STATE = {
  isOpen: false,
  consultationId: null,
  employeeId: null,
  patientId: null,
}

export function createEditConsultationStore(initialState: InitialState = INITIAL_STATE) {
  return createStore<EditConsultationStore>((set) => ({
    ...initialState,
    actions: {
      setIsOpen: (isOpen) => {
        set({ isOpen })
      },
      setConsultationId: (consultationId) => {
        set({ consultationId })
      },
      reset: () => {
        set(INITIAL_STATE)
      },
    },
  }))
}

function useEditConsultationStore<T>(selector: (state: EditConsultationStore) => T) {
  const { store } = useEditConsultationContext()

  return useStore(store, selector)
}

export function useIsOpen() {
  return useEditConsultationStore((state) => state.isOpen)
}

export function useConsultationId() {
  return useEditConsultationStore((state) => state.consultationId)
}

export function useEditConsultationActions() {
  return useEditConsultationStore((state) => state.actions)
}
