'use client'

import React from 'react'
import { useBoolean } from 'ahooks'

import { FocusRing } from '../../misc/focus-ring/focus-ring'
import { Panel } from '../panel/panel'
import { CollapsiblePanelContext } from './collapsible-panel-context'

export interface CollapsiblePanelProps {
  children?: React.ReactNode | (({ isOpen }: { isOpen: boolean }) => React.ReactNode)
  defaultOpen?: boolean
  isOpen?: boolean
  onOpenChange?: (isOpen: boolean) => void
  isDisabled?: boolean
}

export function CollapsiblePanel(props: CollapsiblePanelProps) {
  const [uncontrolledIsOpen, { set }] = useBoolean(props.defaultOpen)

  const isControlled = 'isOpen' in props
  const isOpen = isControlled ? (props.isOpen ?? uncontrolledIsOpen) : uncontrolledIsOpen

  const handleOpenChange = (newIsOpen: boolean) => {
    if (isControlled) {
      props.onOpenChange?.(newIsOpen)
    } else {
      set(newIsOpen)
    }
  }

  return (
    <FocusRing within>
      <Panel className={'overflow-hidden'}>
        <CollapsiblePanelContext.Provider
          value={{ isOpen, isDisabled: props.isDisabled, onOpenChange: handleOpenChange }}
        >
          {typeof props.children === 'function' ? props.children({ isOpen }) : props.children}
        </CollapsiblePanelContext.Provider>
      </Panel>
    </FocusRing>
  )
}

export { CollapsiblePanelContent } from './collapsible-panel-content'
export { CollapsiblePanelTrigger } from './collapsible-panel-trigger'
export { CollapsiblePanelContext } from './collapsible-panel-context'
