import React from 'react'
import { useQueries, useQuery } from '@tanstack/react-query'
import { flatMap, pipe, uniqueBy } from 'remeda'
import { useSpinDelay } from 'spin-delay'

import type { CreateVideoConsultationContextValue } from '../context'

import { clinicEmployeeQueries } from '@/api/fysioscout/endpoints/clinic-employee/queries'
import { clinicPatientQueries } from '@/api/fysioscout/endpoints/clinic-patient/queries'
import { useAuthContext } from '@/features/auth/context'
import { useConsultationCount } from '@/features/video-consultation/hooks/use-consultation-count'

import { CreateVideoConsultationContext } from '../context'
import { createVideoConsultationStore } from '../store'

export interface CreateVideoConsultationProviderProps {
  children?: React.ReactNode
}

export function CreateVideoConsultationProvider({
  children,
}: CreateVideoConsultationProviderProps) {
  const { isLimitReached } = useConsultationCount()
  const employeesQuery = useQuery(clinicEmployeeQueries.list())
  const patientsQuery = useQueries({
    queries: [clinicPatientQueries.list('accepted'), clinicPatientQueries.list('pending')],
    combine: (results) => ({
      data: pipe(
        results,
        (res) => res.filter((result) => result.data),
        flatMap(
          (result) => result.data?.employee_patients.flatMap(({ patients }) => patients) ?? [],
        ),
        uniqueBy((patient) => patient.id),
      ),
      isPending: results.some((result) => result.isPending),
      isLoading: results.some((result) => result.isLoading),
    }),
  })

  const authContext = useAuthContext()

  const isLoading = useSpinDelay(employeesQuery.isLoading || patientsQuery.isLoading, {
    minDuration: 400,
    delay: 0,
  })

  const value = React.useMemo(() => {
    const employees = employeesQuery.data ?? []
    const patients = patientsQuery.data
    const store = createVideoConsultationStore()

    const defaultEmployeeId = employees.find(
      (employee) => employee.user_id === authContext.user.id,
    )?.id

    return {
      store,
      employees,
      patients,
      defaultEmployeeId,
      isLimitReached,
    } satisfies CreateVideoConsultationContextValue
  }, [authContext.user.id, employeesQuery.data, isLimitReached, patientsQuery.data])

  return (
    <CreateVideoConsultationContext.Provider value={{ isLoading, ...value }}>
      {children}
    </CreateVideoConsultationContext.Provider>
  )
}
