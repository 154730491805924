import React from 'react'
import { useCopyToClipboard } from 'usehooks-ts'

type CopyStatus = 'idle' | 'copied'

interface UseCopyToClipboardStateProps {
  timeoutDuration?: number
}

export function useCopyToClipboardState({
  timeoutDuration = 800,
}: UseCopyToClipboardStateProps = {}) {
  const [copiedText, copyToClipboard] = useCopyToClipboard()
  const [copyStatus, setCopyStatus] = React.useState<CopyStatus>('idle')
  const timeoutRef = React.useRef<NodeJS.Timeout | null>(null)

  const copy = React.useCallback(
    async (text: string, options?: { ignoreStatus?: boolean }) => {
      const success = await copyToClipboard(text)

      if (success) {
        if (!options?.ignoreStatus) setCopyStatus('copied')

        if (timeoutRef.current !== null) {
          clearTimeout(timeoutRef.current)
        }

        timeoutRef.current = globalThis.setTimeout(() => {
          setCopyStatus('idle')
          timeoutRef.current = null
        }, timeoutDuration)
      }
    },
    [copyToClipboard, timeoutDuration],
  )

  React.useEffect(() => {
    return () => {
      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [])

  return {
    copiedText,
    copyStatus,
    copy,
  } as const
}
