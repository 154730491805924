import { useMutation, useQueryClient } from '@tanstack/react-query'

import { toast } from '@fysioscout/ui/status/toast'

import { clinicVideoConsultationMutations } from '@/api/fysioscout/endpoints/clinic-video-consultations/mutations'
import { clinicVideoConsultationsQueries } from '@/api/fysioscout/endpoints/clinic-video-consultations/queries'
import { clinicQueries } from '@/api/fysioscout/endpoints/clinic/queries'

/**
 * Cancel a video consultation.
 *
 * @example
 *   const { mutate, isLoading, isError } = useCancelConsultation()
 *   mutate(cancelConsultationOptions)
 *
 * @returns - The result of the mutation.
 */
export function useCancelConsultation() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: clinicVideoConsultationMutations.delete,
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: clinicQueries.getSubscription().queryKey })
      void queryClient.invalidateQueries({ queryKey: clinicVideoConsultationsQueries.all() })
      toast.success('Videokonsultationen blev aflyst.')
    },
  })
}
