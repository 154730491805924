import React from 'react'

import { ComposeProviders } from '@fysioscout/utils/react'

import { CancelConsultation } from '../user-actions/cancel-consultation/components/cancel-consultation'
import { CreateVideoConsultation } from '../user-actions/create-video-consultation/components/create-video-consultation'
import { EditVideoConsultation } from '../user-actions/edit-consultation/components/edit-video-consultation'
import { ResendConsultationEmail } from '../user-actions/resend-consultation-email/components/resend-consultation-email'

interface ConsultationActionsProviderProps {
  children?: React.ReactNode
}

export function ConsultationActionsProvider({ children }: ConsultationActionsProviderProps) {
  return (
    <ComposeProviders
      providers={[
        CreateVideoConsultation,
        EditVideoConsultation,
        ResendConsultationEmail,
        CancelConsultation,
      ]}
    >
      {children}
    </ComposeProviders>
  )
}
