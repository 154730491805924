import React from 'react'
import { useSuspenseQuery } from '@tanstack/react-query'
import { Link } from '@tanstack/react-router'
import { HelpCircle } from 'lucide-react'
import { DialogTrigger } from 'react-aria-components'

import { Button } from '@fysioscout/ui/buttons/button'
import { Label } from '@fysioscout/ui/forms/field'
import { Link as UILink } from '@fysioscout/ui/navigation/link'
import { Dialog } from '@fysioscout/ui/overlays/dialog'
import { Popover } from '@fysioscout/ui/overlays/popover'
import { Heading } from '@fysioscout/ui/typography/heading'
import { Text } from '@fysioscout/ui/typography/text'

import { clinicVideoConsultationsQueries } from '@/api/fysioscout/endpoints/clinic-video-consultations/queries'
import { PageHeading, PageLayout } from '@/components/layouts/page-layout'
import { FirstTimeConsultation } from '@/features/transcription/components/first-time-consultation'
import { FollowUpConsultation } from '@/features/transcription/components/follow-up-consultation'
import { dayjs } from '@/lib/dayjs'

import { Route } from './index'

export function TranscriptionView() {
  const params = Route.useParams()

  const { data: transcription } = useSuspenseQuery(
    clinicVideoConsultationsQueries.transcription({ path: { id: params.consultationId } }),
  )

  return (
    <PageLayout data-testid={'video-consultation-transcription-view'}>
      <div className={'stack gap-8'}>
        <Header />
        <Information />

        {transcription.type === 'first_time' ? (
          <FirstTimeConsultation transcription={transcription.data} />
        ) : null}

        {transcription.type === 'follow_up' ? (
          <FollowUpConsultation transcription={transcription.data} />
        ) : null}
      </div>
    </PageLayout>
  )
}

function Header() {
  const noticeId = React.useId()

  return (
    <div className={'hstack items-end justify-between'}>
      <div className={'stack'}>
        <PageHeading>Transskribering</PageHeading>

        <div className={'hstack items-center gap-0.5'}>
          <Text id={noticeId} size={'1'} muted>
            Det er behandlerens ansvar at kvalitetssikre transskriberingen som journaldata.
          </Text>

          <DialogTrigger>
            <Button aria-labelledby={noticeId} size={'sm'} color={'neutral'} variant={'ghost'}>
              <HelpCircle />
            </Button>

            <Popover className={'max-w-xs'}>
              <Dialog>
                <Heading slot={'title'} size={'2'}>
                  Disclaimer
                </Heading>

                <Text elementType={'p'} size={'1'} subtle className={'mt-1'}>
                  FysioScout fraskriver sig alt ansvar for transskriberingen. Det er
                  fysioterapeutens eget ansvar, at sikre kvaliteten af transskriberingen som
                  journaldata.
                </Text>
              </Dialog>
            </Popover>
          </DialogTrigger>
        </div>
      </div>
    </div>
  )
}

function Information() {
  const params = Route.useParams()

  const { data: consultation } = useSuspenseQuery(
    clinicVideoConsultationsQueries.byId({ path: { id: params.consultationId } }),
  )

  const patientLabelId = React.useId()
  const emailLabelId = React.useId()
  const employeeLabelId = React.useId()
  const durationLabelId = React.useId()
  const detailsLabelId = React.useId()
  const dateLabelId = React.useId()

  return (
    <div className={'border-border-neutral border-y py-8'}>
      <div
        className={
          'hstack 3xl:gap-28 w-full items-center gap-16 overflow-x-scroll whitespace-nowrap text-sm'
        }
      >
        <div className={'stack gap-1'}>
          <Label id={patientLabelId}>Patient</Label>
          <Text aria-labelledby={patientLabelId}>{consultation.patient.name}</Text>
        </div>

        <div className={'stack gap-1'}>
          <Label id={emailLabelId}>Patient email</Label>
          <Text aria-labelledby={emailLabelId}>{consultation.patient.email}</Text>
        </div>

        <div className={'stack gap-1'}>
          <Label id={employeeLabelId}>Behandler</Label>
          <Text aria-labelledby={employeeLabelId}>{consultation.employee.name}</Text>
        </div>

        <div className={'stack gap-1'}>
          <Label id={dateLabelId}>Tidspunkt for afholdelse</Label>
          <Text aria-labelledby={dateLabelId}>
            {dayjs.format(consultation.scheduled_date, { includeTime: true })}
          </Text>
        </div>

        {consultation.duration ? (
          <div className={'stack gap-1'}>
            <Label id={durationLabelId}>Varighed</Label>
            <Text aria-labelledby={durationLabelId}>{consultation.duration} min.</Text>
          </div>
        ) : null}

        <div className={'stack gap-1'}>
          <Label id={detailsLabelId}>Patientdetaljer</Label>

          <UILink aria-labelledby={detailsLabelId} intent={'forward'}>
            <Link
              from={Route.fullPath}
              to={'/patients/$patientId'}
              params={{ patientId: params.patientId }}
            >
              Gå til patientdetaljer
            </Link>
          </UILink>
        </div>
      </div>
    </div>
  )
}
