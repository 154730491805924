import { queryOptions } from '@tanstack/react-query'

import { unwrap } from '@fysioscout/fysioscout-js/utils'

import { fysioscoutClient } from '@/api/fysioscout/client'

export const clinicQueries = {
  all: () => ['clinics'],

  /**
   * Get Clinic Subscription
   *
   * @returns The query option
   * @see {@link https://api.fysioscout.com/api/documentation/scalar#tag/clinic--shared/get/api/clinics/subscription}
   */
  getSubscription: () => {
    return queryOptions({
      queryKey: [...clinicQueries.all(), 'subscription'],
      queryFn: ({ signal }) => {
        return unwrap(fysioscoutClient.GET('/api/clinics/subscription', { signal }))
      },
    })
  },
}
