import { useSuspenseQuery } from '@tanstack/react-query'

import { clinicQueries } from '@/api/fysioscout/endpoints/clinic/queries'

/**
 * Retrieves the consultation count statistics for the current user's subscription. This includes
 * the number of video consultations used and the maximum allowable video consultations.
 *
 * @returns An object containing the used and total consultation count.
 */
export function useConsultationCount() {
  const { data: subscription } = useSuspenseQuery(clinicQueries.getSubscription())

  const { current_video_consultation_usage: used, max_video_consultation_limit: total } =
    subscription.video_consultations

  return {
    used,
    total,
    isLimitReached: used >= total,
  }
}
