import React from 'react'

import { AlertDialog } from '@fysioscout/ui/overlays/alert-dialog'

import { useResendEmail } from '@/features/video-consultation/api/resend-email'

import { useConsultationId, useIsOpen, useResendConsultationEmailActions } from '../store'

export function ResendConsultationEmailDialog() {
  const actions = useResendConsultationEmailActions()
  const consultationId = useConsultationId()
  const isOpen = useIsOpen()
  const mutation = useResendEmail()

  const handleMutation = () => {
    if (!consultationId) return

    mutation.mutate(
      { path: { id: consultationId } },
      {
        onSuccess: () => {
          actions.reset()
        },
      },
    )
  }

  return (
    <AlertDialog
      title={'Gensend link til videokonsultation'}
      actionLabel={'Gensend link'}
      onAction={handleMutation}
      isPending={mutation.isPending}
      isOpen={isOpen}
      onOpenChange={actions.onOpenChange}
    >
      Patienten vil modtage en ny email med et link til videokonsultationen.
    </AlertDialog>
  )
}
