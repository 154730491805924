import React from 'react'
import ms from 'ms'

import { Cell } from '@fysioscout/ui/collections/table'
import { Text } from '@fysioscout/ui/typography/text'

import { useConsultationTableRowContext } from '@/features/video-consultation/contexts/consultation-table-row-context'
import { getTimeDifferenceMinutes } from '@/features/video-consultation/utils'
import { useUpdateTimer } from '@/hooks/use-update-timer'
import { dayjs } from '@/lib/dayjs'

export function CreatedAtCell() {
  const { consultation } = useConsultationTableRowContext()

  const timeDifferenceMin = consultation.created_at
    ? getTimeDifferenceMinutes(consultation.created_at)
    : 0

  const minutesAgo = Math.abs(timeDifferenceMin)

  useUpdateTimer({ intervalMs: ms('30s'), enabled: minutesAgo <= 60 })

  return (
    <Cell>
      <div className={'stack'}>
        <Text size={'2'}>{dayjs.format(consultation.created_at, { includeTime: true })}</Text>

        <Text size={'1'} subtle>
          {dayjs.instance().startOf('minute').to(consultation.created_at)}
        </Text>
      </div>
    </Cell>
  )
}
