import type { StoreApi } from 'zustand'
import type { ResendEmailStore } from './store'

import { createStrictContext } from '@fysioscout/utils/react'

interface ResendEmailContextValue {
  store: StoreApi<ResendEmailStore>
}

export const [ResendEmailContext, useResendEmailContext] =
  createStrictContext<ResendEmailContextValue>({
    name: 'ResendEmailContext',
    errorMessage: 'useResendEmailContext must be used within a ResendEmailContext.Provider.',
    isDevelopment: import.meta.env.DEV,
    allowMissingProvider: false,
  })
