'use client'

import React from 'react'
import {
  TextArea as RACTextArea,
  TextField as RACTextField,
  composeRenderProps,
} from 'react-aria-components'
import { tv } from 'tailwind-variants'

import type {
  TextFieldProps as RACTextFieldProps,
  ValidationResult as RACValidationResult,
} from 'react-aria-components'

import { cn } from '../../utils'
import { Description, FieldError, Label } from '../field/field'
import { fieldBorderStyles, fieldStyles } from '../field/field.styles'

const textAreaStyles = tv({
  extend: fieldBorderStyles,
  base: 'bg-field h-full min-h-24 rounded-md border p-2 text-sm',
})

export interface TextAreaProps extends RACTextFieldProps {
  label?: string
  hideLabel?: boolean
  description?: string | React.ReactNode
  errorMessage?: string | ((validation: RACValidationResult) => string) | React.ReactNode
  placeholder?: string
}

export const TextArea = React.forwardRef<React.ElementRef<typeof RACTextArea>, TextAreaProps>(
  ({ label, description, errorMessage, className, ...rest }, ref) => (
    <RACTextField
      {...rest}
      className={composeRenderProps(className, (className, renderProps) =>
        fieldStyles({ ...renderProps, className }),
      )}
    >
      {label ? <Label className={cn({ 'sr-only': rest.hideLabel })}>{label}</Label> : null}

      <RACTextArea ref={ref} className={textAreaStyles} placeholder={rest.placeholder} />

      {description ? <Description>{description}</Description> : null}
      {rest.isDisabled ? null : <FieldError>{errorMessage}</FieldError>}
    </RACTextField>
  ),
)

TextArea.displayName = 'TextArea'
