import { getLocalTimeZone, today } from '@internationalized/date'
import { match } from 'ts-pattern'

import type { DateDuration } from '@internationalized/date'
import type { DateRange } from 'react-aria'

export type RangeDirection = 'past' | 'future'

export function getDateRange(duration: DateDuration, direction: RangeDirection): DateRange {
  const baseDate = today(getLocalTimeZone())

  return match(direction)
    .with('past', () => ({
      start: baseDate.subtract(duration),
      end: baseDate,
    }))
    .with('future', () => ({
      start: baseDate,
      end: baseDate.add(duration),
    }))
    .exhaustive()
}

export type PresetRangeOption = '24h' | '7d' | '14d' | '30d' | '90d' | '6m' | '1y'
export type PresetRangeGroup = 'all' | 'recent' | 'extended'

export interface PresetRange {
  label: string
  getRange: (direction: RangeDirection) => DateRange
}

export const presetRangeConfig = {
  '24h': {
    label: '24 timer',
    getRange: (direction: RangeDirection) => getDateRange({ days: 1 }, direction),
  },
  '7d': {
    label: '7 dage',
    getRange: (direction: RangeDirection) => getDateRange({ days: 7 }, direction),
  },
  '14d': {
    label: '14 dage',
    getRange: (direction: RangeDirection) => getDateRange({ days: 14 }, direction),
  },
  '30d': {
    label: '30 dage',
    getRange: (direction: RangeDirection) => getDateRange({ days: 30 }, direction),
  },
  '90d': {
    label: '90 dage',
    getRange: (direction: RangeDirection) => getDateRange({ days: 90 }, direction),
  },
  '6m': {
    label: '6 måneder',
    getRange: (direction: RangeDirection) => getDateRange({ months: 6 }, direction),
  },
  '1y': {
    label: 'År',
    getRange: (direction: RangeDirection) => getDateRange({ years: 1 }, direction),
  },
} satisfies Record<PresetRangeOption, PresetRange>
