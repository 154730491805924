'use client'

import React from 'react'
import { ChevronsUpDownIcon } from 'lucide-react'
import {
  Button as RACButton,
  ListBox as RACListBox,
  Select as RACSelect,
  SelectValue as RACSelectValue,
  composeRenderProps,
} from 'react-aria-components'
import { tv } from 'tailwind-variants'

import type {
  ListBoxItemProps as RACListBoxItemProps,
  SelectProps as RACSelectProps,
  ValidationResult as RACValidationResult,
} from 'react-aria-components'
import type { VariantProps } from 'tailwind-variants'
import type { DropdownSectionProps } from '../../collections/list-box/list-box'

import { DropdownItem, DropdownSection } from '../../collections/list-box/list-box'
import { Description, FieldError, Label } from '../../forms/field/field'
import { fieldBorderStyles, fieldStyles } from '../../forms/field/field.styles'
import { Popover } from '../../overlays/popover/popover'
import { cn } from '../../utils'

const selectStyles = tv({
  extend: fieldBorderStyles,
  base: 'bg-field flex w-full min-w-[150px] cursor-default items-center rounded-md border text-start transition',
  variants: {
    isFocused: {
      true: 'border-neutral-7',
    },
    size: {
      '1': 'gap-3 p-2 text-xs',
      '2': 'gap-4 py-2 pl-3 pr-2 text-sm',
    },
  },
  defaultVariants: {
    size: '2',
  },
})

type SelectVariants = VariantProps<typeof selectStyles>

export interface SelectProps<T extends object>
  extends Omit<RACSelectProps<T>, 'children'>,
    SelectVariants {
  label?: string
  description?: string
  errorMessage?: string | ((validation: RACValidationResult) => string)
  items?: Iterable<T>
  children: React.ReactNode | ((item: T) => React.ReactNode)
}

export function Select<T extends object>({
  label,
  description,
  errorMessage,
  children,
  items,
  size = '2',
  ...props
}: SelectProps<T>) {
  return (
    <RACSelect
      {...props}
      className={composeRenderProps(props.className, (className, renderProps) =>
        fieldStyles({ ...renderProps, className }),
      )}
    >
      {label ? <Label>{label}</Label> : null}

      <RACButton className={(renderProps) => selectStyles({ ...renderProps, size })}>
        <RACSelectValue
          className={'placeholder-shown:text-muted-foreground flex-1 placeholder-shown:italic'}
        />

        <ChevronsUpDownIcon
          aria-hidden
          className={cn('text-muted-foreground group-disabled:text-disabled-foreground shrink-0', {
            'size-3.5': size === '1',
            'size-4': size === '2',
          })}
        />
      </RACButton>

      {description ? <Description>{description}</Description> : null}

      <FieldError>{errorMessage}</FieldError>

      <Popover className={'min-w-[--trigger-width]'}>
        <RACListBox items={items} className={'max-h-[inherit] overflow-auto p-1 outline-none'}>
          {children}
        </RACListBox>
      </Popover>
    </RACSelect>
  )
}

export type SelectItemProps = RACListBoxItemProps

export function SelectItem(props: SelectItemProps) {
  return <DropdownItem {...props} />
}

export type SelectSectionProps<T> = DropdownSectionProps<T>

export function SelectSection<T extends object>(props: SelectSectionProps<T>) {
  return <DropdownSection {...props} />
}
