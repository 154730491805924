/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './../routes/__root'
import { Route as AuthRouteImport } from './../routes/_auth/route'
import { Route as AuthIndexImport } from './../routes/_auth/index'
import { Route as AuthLogoutImport } from './../routes/_auth/logout'
import { Route as AuthVideoConsultationRouteImport } from './../routes/_auth/video-consultation/route'
import { Route as AuthPatientsRouteImport } from './../routes/_auth/patients/route'
import { Route as AuthVideoConsultationIndexImport } from './../routes/_auth/video-consultation/index'
import { Route as AuthTemplatesIndexImport } from './../routes/_auth/templates/index'
import { Route as AuthSettingsIndexImport } from './../routes/_auth/settings/index'
import { Route as AuthPractitionersIndexImport } from './../routes/_auth/practitioners/index'
import { Route as AuthPatientsIndexImport } from './../routes/_auth/patients/index'
import { Route as AuthAccountIndexImport } from './../routes/_auth/account/index'
import { Route as AuthPatientsPatientIdRouteImport } from './../routes/_auth/patients/$patientId/route'
import { Route as AuthPatientsPatientIdIndexImport } from './../routes/_auth/patients/$patientId/index'
import { Route as AuthVideoConsultationPatientPatientIdRouteImport } from './../routes/_auth/video-consultation/patient/$patientId/route'
import { Route as AuthVideoConsultationPatientPatientIdIndexImport } from './../routes/_auth/video-consultation/patient/$patientId/index'
import { Route as AuthPatientsPatientIdTreatmentsTreatmentIdImport } from './../routes/_auth/patients/$patientId/treatments/$treatmentId'
import { Route as AuthVideoConsultationPatientPatientIdConsultationConsultationIdTranscriptionIndexImport } from './../routes/_auth/video-consultation/patient/$patientId/consultation/$consultationId/transcription/index'

// Create/Update Routes

const AuthRouteRoute = AuthRouteImport.update({
  id: '/_auth',
  getParentRoute: () => rootRoute,
} as any)

const AuthIndexRoute = AuthIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AuthRouteRoute,
} as any)

const AuthLogoutRoute = AuthLogoutImport.update({
  id: '/logout',
  path: '/logout',
  getParentRoute: () => AuthRouteRoute,
} as any)

const AuthVideoConsultationRouteRoute = AuthVideoConsultationRouteImport.update(
  {
    id: '/video-consultation',
    path: '/video-consultation',
    getParentRoute: () => AuthRouteRoute,
  } as any,
)

const AuthPatientsRouteRoute = AuthPatientsRouteImport.update({
  id: '/patients',
  path: '/patients',
  getParentRoute: () => AuthRouteRoute,
} as any)

const AuthVideoConsultationIndexRoute = AuthVideoConsultationIndexImport.update(
  {
    id: '/',
    path: '/',
    getParentRoute: () => AuthVideoConsultationRouteRoute,
  } as any,
)

const AuthTemplatesIndexRoute = AuthTemplatesIndexImport.update({
  id: '/templates/',
  path: '/templates/',
  getParentRoute: () => AuthRouteRoute,
} as any)

const AuthSettingsIndexRoute = AuthSettingsIndexImport.update({
  id: '/settings/',
  path: '/settings/',
  getParentRoute: () => AuthRouteRoute,
} as any)

const AuthPractitionersIndexRoute = AuthPractitionersIndexImport.update({
  id: '/practitioners/',
  path: '/practitioners/',
  getParentRoute: () => AuthRouteRoute,
} as any)

const AuthPatientsIndexRoute = AuthPatientsIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AuthPatientsRouteRoute,
} as any)

const AuthAccountIndexRoute = AuthAccountIndexImport.update({
  id: '/account/',
  path: '/account/',
  getParentRoute: () => AuthRouteRoute,
} as any)

const AuthPatientsPatientIdRouteRoute = AuthPatientsPatientIdRouteImport.update(
  {
    id: '/$patientId',
    path: '/$patientId',
    getParentRoute: () => AuthPatientsRouteRoute,
  } as any,
)

const AuthPatientsPatientIdIndexRoute = AuthPatientsPatientIdIndexImport.update(
  {
    id: '/',
    path: '/',
    getParentRoute: () => AuthPatientsPatientIdRouteRoute,
  } as any,
)

const AuthVideoConsultationPatientPatientIdRouteRoute =
  AuthVideoConsultationPatientPatientIdRouteImport.update({
    id: '/patient/$patientId',
    path: '/patient/$patientId',
    getParentRoute: () => AuthVideoConsultationRouteRoute,
  } as any)

const AuthVideoConsultationPatientPatientIdIndexRoute =
  AuthVideoConsultationPatientPatientIdIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => AuthVideoConsultationPatientPatientIdRouteRoute,
  } as any)

const AuthPatientsPatientIdTreatmentsTreatmentIdRoute =
  AuthPatientsPatientIdTreatmentsTreatmentIdImport.update({
    id: '/treatments/$treatmentId',
    path: '/treatments/$treatmentId',
    getParentRoute: () => AuthPatientsPatientIdRouteRoute,
  } as any)

const AuthVideoConsultationPatientPatientIdConsultationConsultationIdTranscriptionIndexRoute =
  AuthVideoConsultationPatientPatientIdConsultationConsultationIdTranscriptionIndexImport.update(
    {
      id: '/consultation/$consultationId/transcription/',
      path: '/consultation/$consultationId/transcription/',
      getParentRoute: () => AuthVideoConsultationPatientPatientIdRouteRoute,
    } as any,
  )

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_auth': {
      id: '/_auth'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthRouteImport
      parentRoute: typeof rootRoute
    }
    '/_auth/patients': {
      id: '/_auth/patients'
      path: '/patients'
      fullPath: '/patients'
      preLoaderRoute: typeof AuthPatientsRouteImport
      parentRoute: typeof AuthRouteImport
    }
    '/_auth/video-consultation': {
      id: '/_auth/video-consultation'
      path: '/video-consultation'
      fullPath: '/video-consultation'
      preLoaderRoute: typeof AuthVideoConsultationRouteImport
      parentRoute: typeof AuthRouteImport
    }
    '/_auth/logout': {
      id: '/_auth/logout'
      path: '/logout'
      fullPath: '/logout'
      preLoaderRoute: typeof AuthLogoutImport
      parentRoute: typeof AuthRouteImport
    }
    '/_auth/': {
      id: '/_auth/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof AuthIndexImport
      parentRoute: typeof AuthRouteImport
    }
    '/_auth/patients/$patientId': {
      id: '/_auth/patients/$patientId'
      path: '/$patientId'
      fullPath: '/patients/$patientId'
      preLoaderRoute: typeof AuthPatientsPatientIdRouteImport
      parentRoute: typeof AuthPatientsRouteImport
    }
    '/_auth/account/': {
      id: '/_auth/account/'
      path: '/account'
      fullPath: '/account'
      preLoaderRoute: typeof AuthAccountIndexImport
      parentRoute: typeof AuthRouteImport
    }
    '/_auth/patients/': {
      id: '/_auth/patients/'
      path: '/'
      fullPath: '/patients/'
      preLoaderRoute: typeof AuthPatientsIndexImport
      parentRoute: typeof AuthPatientsRouteImport
    }
    '/_auth/practitioners/': {
      id: '/_auth/practitioners/'
      path: '/practitioners'
      fullPath: '/practitioners'
      preLoaderRoute: typeof AuthPractitionersIndexImport
      parentRoute: typeof AuthRouteImport
    }
    '/_auth/settings/': {
      id: '/_auth/settings/'
      path: '/settings'
      fullPath: '/settings'
      preLoaderRoute: typeof AuthSettingsIndexImport
      parentRoute: typeof AuthRouteImport
    }
    '/_auth/templates/': {
      id: '/_auth/templates/'
      path: '/templates'
      fullPath: '/templates'
      preLoaderRoute: typeof AuthTemplatesIndexImport
      parentRoute: typeof AuthRouteImport
    }
    '/_auth/video-consultation/': {
      id: '/_auth/video-consultation/'
      path: '/'
      fullPath: '/video-consultation/'
      preLoaderRoute: typeof AuthVideoConsultationIndexImport
      parentRoute: typeof AuthVideoConsultationRouteImport
    }
    '/_auth/video-consultation/patient/$patientId': {
      id: '/_auth/video-consultation/patient/$patientId'
      path: '/patient/$patientId'
      fullPath: '/video-consultation/patient/$patientId'
      preLoaderRoute: typeof AuthVideoConsultationPatientPatientIdRouteImport
      parentRoute: typeof AuthVideoConsultationRouteImport
    }
    '/_auth/patients/$patientId/': {
      id: '/_auth/patients/$patientId/'
      path: '/'
      fullPath: '/patients/$patientId/'
      preLoaderRoute: typeof AuthPatientsPatientIdIndexImport
      parentRoute: typeof AuthPatientsPatientIdRouteImport
    }
    '/_auth/patients/$patientId/treatments/$treatmentId': {
      id: '/_auth/patients/$patientId/treatments/$treatmentId'
      path: '/treatments/$treatmentId'
      fullPath: '/patients/$patientId/treatments/$treatmentId'
      preLoaderRoute: typeof AuthPatientsPatientIdTreatmentsTreatmentIdImport
      parentRoute: typeof AuthPatientsPatientIdRouteImport
    }
    '/_auth/video-consultation/patient/$patientId/': {
      id: '/_auth/video-consultation/patient/$patientId/'
      path: '/'
      fullPath: '/video-consultation/patient/$patientId/'
      preLoaderRoute: typeof AuthVideoConsultationPatientPatientIdIndexImport
      parentRoute: typeof AuthVideoConsultationPatientPatientIdRouteImport
    }
    '/_auth/video-consultation/patient/$patientId/consultation/$consultationId/transcription/': {
      id: '/_auth/video-consultation/patient/$patientId/consultation/$consultationId/transcription/'
      path: '/consultation/$consultationId/transcription'
      fullPath: '/video-consultation/patient/$patientId/consultation/$consultationId/transcription'
      preLoaderRoute: typeof AuthVideoConsultationPatientPatientIdConsultationConsultationIdTranscriptionIndexImport
      parentRoute: typeof AuthVideoConsultationPatientPatientIdRouteImport
    }
  }
}

// Create and export the route tree

interface AuthPatientsPatientIdRouteRouteChildren {
  AuthPatientsPatientIdIndexRoute: typeof AuthPatientsPatientIdIndexRoute
  AuthPatientsPatientIdTreatmentsTreatmentIdRoute: typeof AuthPatientsPatientIdTreatmentsTreatmentIdRoute
}

const AuthPatientsPatientIdRouteRouteChildren: AuthPatientsPatientIdRouteRouteChildren =
  {
    AuthPatientsPatientIdIndexRoute: AuthPatientsPatientIdIndexRoute,
    AuthPatientsPatientIdTreatmentsTreatmentIdRoute:
      AuthPatientsPatientIdTreatmentsTreatmentIdRoute,
  }

const AuthPatientsPatientIdRouteRouteWithChildren =
  AuthPatientsPatientIdRouteRoute._addFileChildren(
    AuthPatientsPatientIdRouteRouteChildren,
  )

interface AuthPatientsRouteRouteChildren {
  AuthPatientsPatientIdRouteRoute: typeof AuthPatientsPatientIdRouteRouteWithChildren
  AuthPatientsIndexRoute: typeof AuthPatientsIndexRoute
}

const AuthPatientsRouteRouteChildren: AuthPatientsRouteRouteChildren = {
  AuthPatientsPatientIdRouteRoute: AuthPatientsPatientIdRouteRouteWithChildren,
  AuthPatientsIndexRoute: AuthPatientsIndexRoute,
}

const AuthPatientsRouteRouteWithChildren =
  AuthPatientsRouteRoute._addFileChildren(AuthPatientsRouteRouteChildren)

interface AuthVideoConsultationPatientPatientIdRouteRouteChildren {
  AuthVideoConsultationPatientPatientIdIndexRoute: typeof AuthVideoConsultationPatientPatientIdIndexRoute
  AuthVideoConsultationPatientPatientIdConsultationConsultationIdTranscriptionIndexRoute: typeof AuthVideoConsultationPatientPatientIdConsultationConsultationIdTranscriptionIndexRoute
}

const AuthVideoConsultationPatientPatientIdRouteRouteChildren: AuthVideoConsultationPatientPatientIdRouteRouteChildren =
  {
    AuthVideoConsultationPatientPatientIdIndexRoute:
      AuthVideoConsultationPatientPatientIdIndexRoute,
    AuthVideoConsultationPatientPatientIdConsultationConsultationIdTranscriptionIndexRoute:
      AuthVideoConsultationPatientPatientIdConsultationConsultationIdTranscriptionIndexRoute,
  }

const AuthVideoConsultationPatientPatientIdRouteRouteWithChildren =
  AuthVideoConsultationPatientPatientIdRouteRoute._addFileChildren(
    AuthVideoConsultationPatientPatientIdRouteRouteChildren,
  )

interface AuthVideoConsultationRouteRouteChildren {
  AuthVideoConsultationIndexRoute: typeof AuthVideoConsultationIndexRoute
  AuthVideoConsultationPatientPatientIdRouteRoute: typeof AuthVideoConsultationPatientPatientIdRouteRouteWithChildren
}

const AuthVideoConsultationRouteRouteChildren: AuthVideoConsultationRouteRouteChildren =
  {
    AuthVideoConsultationIndexRoute: AuthVideoConsultationIndexRoute,
    AuthVideoConsultationPatientPatientIdRouteRoute:
      AuthVideoConsultationPatientPatientIdRouteRouteWithChildren,
  }

const AuthVideoConsultationRouteRouteWithChildren =
  AuthVideoConsultationRouteRoute._addFileChildren(
    AuthVideoConsultationRouteRouteChildren,
  )

interface AuthRouteRouteChildren {
  AuthPatientsRouteRoute: typeof AuthPatientsRouteRouteWithChildren
  AuthVideoConsultationRouteRoute: typeof AuthVideoConsultationRouteRouteWithChildren
  AuthLogoutRoute: typeof AuthLogoutRoute
  AuthIndexRoute: typeof AuthIndexRoute
  AuthAccountIndexRoute: typeof AuthAccountIndexRoute
  AuthPractitionersIndexRoute: typeof AuthPractitionersIndexRoute
  AuthSettingsIndexRoute: typeof AuthSettingsIndexRoute
  AuthTemplatesIndexRoute: typeof AuthTemplatesIndexRoute
}

const AuthRouteRouteChildren: AuthRouteRouteChildren = {
  AuthPatientsRouteRoute: AuthPatientsRouteRouteWithChildren,
  AuthVideoConsultationRouteRoute: AuthVideoConsultationRouteRouteWithChildren,
  AuthLogoutRoute: AuthLogoutRoute,
  AuthIndexRoute: AuthIndexRoute,
  AuthAccountIndexRoute: AuthAccountIndexRoute,
  AuthPractitionersIndexRoute: AuthPractitionersIndexRoute,
  AuthSettingsIndexRoute: AuthSettingsIndexRoute,
  AuthTemplatesIndexRoute: AuthTemplatesIndexRoute,
}

const AuthRouteRouteWithChildren = AuthRouteRoute._addFileChildren(
  AuthRouteRouteChildren,
)

export interface FileRoutesByFullPath {
  '': typeof AuthRouteRouteWithChildren
  '/patients': typeof AuthPatientsRouteRouteWithChildren
  '/video-consultation': typeof AuthVideoConsultationRouteRouteWithChildren
  '/logout': typeof AuthLogoutRoute
  '/': typeof AuthIndexRoute
  '/patients/$patientId': typeof AuthPatientsPatientIdRouteRouteWithChildren
  '/account': typeof AuthAccountIndexRoute
  '/patients/': typeof AuthPatientsIndexRoute
  '/practitioners': typeof AuthPractitionersIndexRoute
  '/settings': typeof AuthSettingsIndexRoute
  '/templates': typeof AuthTemplatesIndexRoute
  '/video-consultation/': typeof AuthVideoConsultationIndexRoute
  '/video-consultation/patient/$patientId': typeof AuthVideoConsultationPatientPatientIdRouteRouteWithChildren
  '/patients/$patientId/': typeof AuthPatientsPatientIdIndexRoute
  '/patients/$patientId/treatments/$treatmentId': typeof AuthPatientsPatientIdTreatmentsTreatmentIdRoute
  '/video-consultation/patient/$patientId/': typeof AuthVideoConsultationPatientPatientIdIndexRoute
  '/video-consultation/patient/$patientId/consultation/$consultationId/transcription': typeof AuthVideoConsultationPatientPatientIdConsultationConsultationIdTranscriptionIndexRoute
}

export interface FileRoutesByTo {
  '/logout': typeof AuthLogoutRoute
  '/': typeof AuthIndexRoute
  '/account': typeof AuthAccountIndexRoute
  '/patients': typeof AuthPatientsIndexRoute
  '/practitioners': typeof AuthPractitionersIndexRoute
  '/settings': typeof AuthSettingsIndexRoute
  '/templates': typeof AuthTemplatesIndexRoute
  '/video-consultation': typeof AuthVideoConsultationIndexRoute
  '/patients/$patientId': typeof AuthPatientsPatientIdIndexRoute
  '/patients/$patientId/treatments/$treatmentId': typeof AuthPatientsPatientIdTreatmentsTreatmentIdRoute
  '/video-consultation/patient/$patientId': typeof AuthVideoConsultationPatientPatientIdIndexRoute
  '/video-consultation/patient/$patientId/consultation/$consultationId/transcription': typeof AuthVideoConsultationPatientPatientIdConsultationConsultationIdTranscriptionIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/_auth': typeof AuthRouteRouteWithChildren
  '/_auth/patients': typeof AuthPatientsRouteRouteWithChildren
  '/_auth/video-consultation': typeof AuthVideoConsultationRouteRouteWithChildren
  '/_auth/logout': typeof AuthLogoutRoute
  '/_auth/': typeof AuthIndexRoute
  '/_auth/patients/$patientId': typeof AuthPatientsPatientIdRouteRouteWithChildren
  '/_auth/account/': typeof AuthAccountIndexRoute
  '/_auth/patients/': typeof AuthPatientsIndexRoute
  '/_auth/practitioners/': typeof AuthPractitionersIndexRoute
  '/_auth/settings/': typeof AuthSettingsIndexRoute
  '/_auth/templates/': typeof AuthTemplatesIndexRoute
  '/_auth/video-consultation/': typeof AuthVideoConsultationIndexRoute
  '/_auth/video-consultation/patient/$patientId': typeof AuthVideoConsultationPatientPatientIdRouteRouteWithChildren
  '/_auth/patients/$patientId/': typeof AuthPatientsPatientIdIndexRoute
  '/_auth/patients/$patientId/treatments/$treatmentId': typeof AuthPatientsPatientIdTreatmentsTreatmentIdRoute
  '/_auth/video-consultation/patient/$patientId/': typeof AuthVideoConsultationPatientPatientIdIndexRoute
  '/_auth/video-consultation/patient/$patientId/consultation/$consultationId/transcription/': typeof AuthVideoConsultationPatientPatientIdConsultationConsultationIdTranscriptionIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | ''
    | '/patients'
    | '/video-consultation'
    | '/logout'
    | '/'
    | '/patients/$patientId'
    | '/account'
    | '/patients/'
    | '/practitioners'
    | '/settings'
    | '/templates'
    | '/video-consultation/'
    | '/video-consultation/patient/$patientId'
    | '/patients/$patientId/'
    | '/patients/$patientId/treatments/$treatmentId'
    | '/video-consultation/patient/$patientId/'
    | '/video-consultation/patient/$patientId/consultation/$consultationId/transcription'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/logout'
    | '/'
    | '/account'
    | '/patients'
    | '/practitioners'
    | '/settings'
    | '/templates'
    | '/video-consultation'
    | '/patients/$patientId'
    | '/patients/$patientId/treatments/$treatmentId'
    | '/video-consultation/patient/$patientId'
    | '/video-consultation/patient/$patientId/consultation/$consultationId/transcription'
  id:
    | '__root__'
    | '/_auth'
    | '/_auth/patients'
    | '/_auth/video-consultation'
    | '/_auth/logout'
    | '/_auth/'
    | '/_auth/patients/$patientId'
    | '/_auth/account/'
    | '/_auth/patients/'
    | '/_auth/practitioners/'
    | '/_auth/settings/'
    | '/_auth/templates/'
    | '/_auth/video-consultation/'
    | '/_auth/video-consultation/patient/$patientId'
    | '/_auth/patients/$patientId/'
    | '/_auth/patients/$patientId/treatments/$treatmentId'
    | '/_auth/video-consultation/patient/$patientId/'
    | '/_auth/video-consultation/patient/$patientId/consultation/$consultationId/transcription/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  AuthRouteRoute: typeof AuthRouteRouteWithChildren
}

const rootRouteChildren: RootRouteChildren = {
  AuthRouteRoute: AuthRouteRouteWithChildren,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_auth"
      ]
    },
    "/_auth": {
      "filePath": "_auth/route.tsx",
      "children": [
        "/_auth/patients",
        "/_auth/video-consultation",
        "/_auth/logout",
        "/_auth/",
        "/_auth/account/",
        "/_auth/practitioners/",
        "/_auth/settings/",
        "/_auth/templates/"
      ]
    },
    "/_auth/patients": {
      "filePath": "_auth/patients/route.tsx",
      "parent": "/_auth",
      "children": [
        "/_auth/patients/$patientId",
        "/_auth/patients/"
      ]
    },
    "/_auth/video-consultation": {
      "filePath": "_auth/video-consultation/route.tsx",
      "parent": "/_auth",
      "children": [
        "/_auth/video-consultation/",
        "/_auth/video-consultation/patient/$patientId"
      ]
    },
    "/_auth/logout": {
      "filePath": "_auth/logout.tsx",
      "parent": "/_auth"
    },
    "/_auth/": {
      "filePath": "_auth/index.tsx",
      "parent": "/_auth"
    },
    "/_auth/patients/$patientId": {
      "filePath": "_auth/patients/$patientId/route.tsx",
      "parent": "/_auth/patients",
      "children": [
        "/_auth/patients/$patientId/",
        "/_auth/patients/$patientId/treatments/$treatmentId"
      ]
    },
    "/_auth/account/": {
      "filePath": "_auth/account/index.tsx",
      "parent": "/_auth"
    },
    "/_auth/patients/": {
      "filePath": "_auth/patients/index.tsx",
      "parent": "/_auth/patients"
    },
    "/_auth/practitioners/": {
      "filePath": "_auth/practitioners/index.tsx",
      "parent": "/_auth"
    },
    "/_auth/settings/": {
      "filePath": "_auth/settings/index.tsx",
      "parent": "/_auth"
    },
    "/_auth/templates/": {
      "filePath": "_auth/templates/index.tsx",
      "parent": "/_auth"
    },
    "/_auth/video-consultation/": {
      "filePath": "_auth/video-consultation/index.tsx",
      "parent": "/_auth/video-consultation"
    },
    "/_auth/video-consultation/patient/$patientId": {
      "filePath": "_auth/video-consultation/patient/$patientId/route.tsx",
      "parent": "/_auth/video-consultation",
      "children": [
        "/_auth/video-consultation/patient/$patientId/",
        "/_auth/video-consultation/patient/$patientId/consultation/$consultationId/transcription/"
      ]
    },
    "/_auth/patients/$patientId/": {
      "filePath": "_auth/patients/$patientId/index.tsx",
      "parent": "/_auth/patients/$patientId"
    },
    "/_auth/patients/$patientId/treatments/$treatmentId": {
      "filePath": "_auth/patients/$patientId/treatments/$treatmentId.tsx",
      "parent": "/_auth/patients/$patientId"
    },
    "/_auth/video-consultation/patient/$patientId/": {
      "filePath": "_auth/video-consultation/patient/$patientId/index.tsx",
      "parent": "/_auth/video-consultation/patient/$patientId"
    },
    "/_auth/video-consultation/patient/$patientId/consultation/$consultationId/transcription/": {
      "filePath": "_auth/video-consultation/patient/$patientId/consultation/$consultationId/transcription/index.tsx",
      "parent": "/_auth/video-consultation/patient/$patientId"
    }
  }
}
ROUTE_MANIFEST_END */
