import React from 'react'

import type { Schemas } from '@fysioscout/fysioscout-js/type-helpers'
import type { PanelProps } from '@fysioscout/ui/layout/panel'

import { Panel, PanelHeading } from '@fysioscout/ui/layout/panel'
import { Badge } from '@fysioscout/ui/status/badge'

import { TreatmentTable } from '@/features/treatments/components/treatment/treatment-table'

interface TreatmentListProps extends PanelProps {
  /** The unique identifier for the patient. */
  patient: Schemas['PatientWithEmployee']

  /** The treatments for the patient. */
  treatments: Schemas['TreatmentDto'][]
}

export function TreatmentList({ patient, treatments, ...rest }: TreatmentListProps) {
  const headingId = React.useId()

  return (
    <Panel data-testid={'treatment-list'} {...rest}>
      <div className={'hstack items-center'}>
        <PanelHeading id={headingId}>Behandlingsforløb</PanelHeading>
        <Badge variant={'neutral'}>{treatments.length}</Badge>
      </div>

      <TreatmentTable aria-labelledby={headingId} patient={patient} treatments={treatments} />
    </Panel>
  )
}
