import { queryOptions } from '@tanstack/react-query'
import { sortBy } from 'remeda'

import { unwrap } from '@fysioscout/fysioscout-js/utils'

import { fysioscoutClient } from '@/api/fysioscout/client'

export const clinicEmployeeQueries = {
  all: () => ['clinic-employees'],
  lists: () => [...clinicEmployeeQueries.all(), 'lists'],
  list: () => {
    return queryOptions({
      queryKey: [...clinicEmployeeQueries.lists(), 'list'],
      queryFn: async ({ signal }) => {
        const employees = await unwrap(fysioscoutClient.GET('/api/clinics/employees', { signal }))

        return sortBy(employees, (employee) => employee.name)
      },
    })
  },
}
