import React from 'react'
import { today } from '@internationalized/date'
import { words } from 'string-ts'
import { tv } from 'tailwind-variants'

import type { AlertDialogProps } from '@fysioscout/ui/overlays/alert-dialog'
import type { CalendarDate, Time } from '@internationalized/date'

import { DEFAULT_TIME_ZONE } from '@fysioscout/env/date-time'
import { Label } from '@fysioscout/ui/forms/field'
import { AlertDialog } from '@fysioscout/ui/overlays/alert-dialog'
import { Text } from '@fysioscout/ui/typography/text'

import { dayjs } from '@/lib/dayjs'

const dialogContentGridStyles = tv({
  base: 'text-foreground mt-6 grid grid-cols-2 gap-x-4 gap-y-5',
})

export interface ConfirmConsultationDialogProps extends Partial<AlertDialogProps> {
  patientName: string
  practitionerName: string
  selectedDate: CalendarDate | null
  selectedSelf: boolean
  selectedTime: Time | null
  treatmentName?: string
}

export function ConfirmPlannedConsultationDialog({
  patientName,
  practitionerName,
  selectedDate,
  selectedSelf,
  selectedTime,
  treatmentName,
  ...rest
}: ConfirmConsultationDialogProps) {
  const isTomorrowOrLater = selectedDate ? selectedDate.compare(today(DEFAULT_TIME_ZONE)) > 0 : true

  return (
    <AlertDialog
      title={'Er du sikker på at du vil oprette konsultationen?'}
      actionLabel={'Opret videokonsultation'}
      size={'2'}
      {...rest}
    >
      <Text>
        Links vil automatisk blive sendt til{' '}
        {selectedSelf ? 'din' : getEmployeeReference(practitionerName)} og patientens e-mail, når
        konsultationen skal finde sted.
      </Text>

      <dl className={dialogContentGridStyles({ className: 'grid-cols-[1fr_1.2fr]' })}>
        <div>
          <Label elementType={'dt'}>Behandler</Label>
          <dd>{practitionerName}</dd>
        </div>

        <div>
          <Label elementType={'dt'}>Patient</Label>
          <dd>
            {patientName} {treatmentName ? `(${treatmentName})` : null}
          </dd>
        </div>

        {selectedDate ? (
          <div>
            <Label elementType={'dt'}>Dato</Label>
            <dd>
              {dayjs.format(selectedDate.toString())}{' '}
              {isTomorrowOrLater ? `(${dayjs.to(selectedDate.toString())})` : '(i dag)'}
            </dd>
          </div>
        ) : null}

        {selectedTime ? (
          <div>
            <Label elementType={'dt'}>Tidspunkt</Label>
            <dd>{selectedTime.toString().slice(0, 5)}</dd>
          </div>
        ) : null}
      </dl>
    </AlertDialog>
  )
}

export function ConfirmImmediateConsultationDialog({
  patientName,
  practitionerName,
  selectedSelf,
  treatmentName,
  ...rest
}: ConfirmConsultationDialogProps) {
  return (
    <AlertDialog
      {...rest}
      title={'Er du sikker på du vil starte mødet nu?'}
      actionLabel={'Start videokonsultation'}
      size={'2'}
    >
      <Text>
        Links vil automatisk blive sendt til{' '}
        {selectedSelf ? 'din' : getEmployeeReference(practitionerName)} og patientens e-mail med det
        samme.
      </Text>

      <dl className={dialogContentGridStyles({ className: 'grid-cols-[1fr_1.5fr]' })}>
        <div>
          <Label elementType={'dt'}>Behandler</Label>
          <dd>{practitionerName}</dd>
        </div>

        <div>
          <Label elementType={'dt'}>Patient</Label>
          <dd>
            {patientName} {treatmentName ? `(${treatmentName})` : null}
          </dd>
        </div>
      </dl>
    </AlertDialog>
  )
}

function getFirstName(fullName: string) {
  return words(fullName).at(0) ?? 'behandleren'
}

function getEmployeeReference(fullName: string) {
  return `${getFirstName(fullName)}s`
}
