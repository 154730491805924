import { createFileRoute } from '@tanstack/react-router'
import { titleCase } from 'string-ts'

import { clinicPatientQueries } from '@/api/fysioscout/endpoints/clinic-patient/queries'

export const Route = createFileRoute('/_auth/video-consultation/patient/$patientId')({
  beforeLoad: async ({ context: { queryClient }, params: { patientId } }) => {
    const patient = await queryClient.ensureQueryData(clinicPatientQueries.getById(patientId))

    return {
      patient,
    }
  },
  loader: ({ context: { patient } }) => ({ patient }),
  meta: ({ loaderData: { patient } }) => [{ title: titleCase(patient.name ?? patient.email) }],
})
