import { createStore, useStore } from 'zustand'

import type { Schemas } from '@fysioscout/fysioscout-js/type-helpers'

import { useResendEmailContext } from './context'

type ConsultationId = Schemas['ConsultationResponseDto']['id']

export interface ResendEmailStore {
  consultationId: ConsultationId | null
  actions: {
    setConsultationId: (consultationId: ConsultationId | null) => void
    onOpenChange: (isOpen: boolean) => void
    reset: () => void
  }
}

export type InitialState = Omit<ResendEmailStore, 'actions'>

const INITIAL_STATE = {
  consultationId: null,
}

export function createResendEmailStore(initialState: InitialState = INITIAL_STATE) {
  return createStore<ResendEmailStore>((set) => ({
    ...initialState,
    actions: {
      setConsultationId: (consultationId) => {
        set({ consultationId })
      },
      onOpenChange: (isOpen) => {
        set({ consultationId: isOpen ? initialState.consultationId : null })
      },
      reset: () => {
        set(INITIAL_STATE)
      },
    },
  }))
}

function useResendEmailStore<T>(selector: (state: ResendEmailStore) => T) {
  const { store } = useResendEmailContext()

  return useStore(store, selector)
}

export function useConsultationId() {
  return useResendEmailStore((state) => state.consultationId)
}

export function useIsOpen() {
  return useResendEmailStore((state) => Boolean(state.consultationId))
}

export function useResendConsultationEmailActions() {
  return useResendEmailStore((state) => state.actions)
}
