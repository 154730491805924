import { createFileRoute } from '@tanstack/react-router'
import { titleCase } from 'string-ts'

import { clinicPatientQueries } from '@/api/fysioscout/endpoints/clinic-patient/queries'
import { clinicTreatmentQueries } from '@/api/fysioscout/endpoints/clinic-treatment/queries'
import { PatientRoute } from '@/app/routes/_auth/patients/$patientId/-patient-route'

export const Route = createFileRoute('/_auth/patients/$patientId')({
  beforeLoad: async ({ context: { queryClient }, params: { patientId } }) => {
    const [patient] = await Promise.all([
      queryClient.ensureQueryData(clinicPatientQueries.getById(patientId)),
      queryClient.prefetchQuery(clinicTreatmentQueries.treatments({ path: { patientId } })),
    ])

    return {
      patient,
    }
  },
  loader: ({ context: { patient } }) => ({ patient }),
  meta: ({ loaderData: { patient } }) => [{ title: titleCase(patient.name ?? patient.email) }],
  component: PatientRoute,
})
