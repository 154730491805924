import React from 'react'

import type { Schemas } from '@fysioscout/fysioscout-js/type-helpers'

import { Panel, PanelHeading } from '@fysioscout/ui/layout/panel'
import { Badge } from '@fysioscout/ui/status/badge'

import { PatientFinishedVcTable } from './tables/patient-finished-vc-table'

interface FinishedConsultationsProps {
  consultations: Schemas['ConsultationResponseDto'][]
}

export function FinishedConsultations({ consultations }: FinishedConsultationsProps) {
  const headingId = React.useId()

  return (
    <Panel>
      <div className={'hstack items-center'}>
        <PanelHeading id={headingId}>Afholdte videokonsultationer</PanelHeading>
        <Badge variant={'neutral'}>{consultations.length}</Badge>
      </div>

      <PatientFinishedVcTable aria-labelledby={headingId} consultations={consultations} />
    </Panel>
  )
}
