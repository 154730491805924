import React from 'react'
import ms from 'ms'

import { Cell } from '@fysioscout/ui/collections/table'
import { Text } from '@fysioscout/ui/typography/text'

import { useConsultationTableRowContext } from '@/features/video-consultation/contexts/consultation-table-row-context'
import { getTimeDifferenceMinutes } from '@/features/video-consultation/utils'
import { useUpdateTimer } from '@/hooks/use-update-timer'
import { dayjs } from '@/lib/dayjs'

const TIME_WINDOWS = {
  UPDATE_INTERVAL_MS: ms('10s'),
  ACTIVE_WINDOW_MIN: 60,
  WARNING_WINDOW_MIN: 120,
} as const

const TIME_STATES = {
  PAST: 'negative',
  UPCOMING: 'info',
  FUTURE: 'subtle',
  INACTIVE: 'subtle',
} as const

export function ScheduledDateCell() {
  const { consultation } = useConsultationTableRowContext()

  const timeDifferenceMin = getTimeDifferenceMinutes(consultation.scheduled_date)

  useUpdateTimer({ intervalMs: ms('10s'), enabled: timeDifferenceMin <= 60 })

  const textColor = React.useMemo(() => {
    if (consultation.status !== 'scheduled') return TIME_STATES.INACTIVE

    if (timeDifferenceMin < 0) return TIME_STATES.PAST
    if (timeDifferenceMin <= TIME_WINDOWS.WARNING_WINDOW_MIN) return TIME_STATES.UPCOMING

    return TIME_STATES.FUTURE
  }, [consultation.status, timeDifferenceMin])

  return (
    <Cell>
      <div className={'stack'}>
        <Text>{dayjs.format(consultation.scheduled_date, { includeTime: true })}</Text>

        <Text size={'1'} color={textColor}>
          {dayjs.instance().startOf('minute').to(consultation.scheduled_date)}
        </Text>
      </div>
    </Cell>
  )
}
