import React from 'react'

import { Cell } from '@fysioscout/ui/collections/table'
import { Text } from '@fysioscout/ui/typography/text'

import { useConsultationTableRowContext } from '@/features/video-consultation/contexts/consultation-table-row-context'

export function EmployeeCell() {
  const { consultation } = useConsultationTableRowContext()

  return (
    <Cell>
      <Text>{consultation.employee.name}</Text>
    </Cell>
  )
}
