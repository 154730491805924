import type { GetClinicJournalOptions } from '@/api/fysioscout/endpoints/clinic-journal/models'

import { unwrap } from '@fysioscout/fysioscout-js/utils'

import { fysioscoutClient } from '@/api/fysioscout/client'

export const clinicJournalMutations = {
  /**
   * Generate URL To View Journal By Treatment id - Admins
   *
   * @param params - The params for the query
   * @returns The URL to view the journal
   * @see {@link https://api.fysioscout.com/api/documentation/redoc#tag/Clinic-Journal/paths/~1api~1clinics~1journal~1url~1%7Bid%7D/get}
   */
  generateUrl: (params: GetClinicJournalOptions['params']) => {
    return unwrap(fysioscoutClient.GET('/api/clinics/journal/url/{id}', { params }))
  },
}
