import React from 'react'
import { useInterval } from 'ahooks'

interface UseUpdateTimerProps {
  intervalMs: number
  enabled?: boolean
  onUpdate?: () => void
}

export function useUpdateTimer({ intervalMs, enabled = true, onUpdate }: UseUpdateTimerProps) {
  const [, setUpdateCounter] = React.useState(0)

  useInterval(
    () => {
      setUpdateCounter((prev) => prev + 1)
      onUpdate?.()
    },
    enabled ? intervalMs : undefined,
  )
}
