import { createFileRoute } from '@tanstack/react-router'

import { clinicTreatmentQueries } from '@/api/fysioscout/endpoints/clinic-treatment/queries'
import { clinicVideoConsultationsQueries } from '@/api/fysioscout/endpoints/clinic-video-consultations/queries'

import { PatientView } from './-patient-view'

export const Route = createFileRoute('/_auth/patients/$patientId/')({
  component: PatientView,
  loader: async ({ context: { queryClient }, params: { patientId } }) => {
    const [treatments, scheduledConsultations] = await Promise.all([
      queryClient.ensureQueryData(clinicTreatmentQueries.treatments({ path: { patientId } })),
      queryClient.ensureQueryData(
        clinicVideoConsultationsQueries.byPatientId({
          path: { id: patientId },
          query: { status: 'scheduled' },
        }),
      ),
    ])

    return {
      treatments,
      scheduledConsultations,
    }
  },
})
