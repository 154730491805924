import type { Schemas } from '@fysioscout/fysioscout-js/type-helpers'
import type { StoreApi } from 'zustand'
import type { CreateVideoConsultationStore } from './store'

import { createStrictContext } from '@fysioscout/utils/react'

export interface CreateVideoConsultationContextValue {
  store: StoreApi<CreateVideoConsultationStore>
  isLimitReached?: boolean
  employees: Schemas['ClinicEmployeesDetails'][]
  patients: Schemas['PatientWithEmployee'][]
  defaultEmployeeId?: Schemas['ClinicEmployeesDetails']['id']
  isLoading?: boolean
}

export const [CreateVideoConsultationContext, useCreateVideoConsultationContext] =
  createStrictContext<CreateVideoConsultationContextValue>({
    name: 'CreateVideoConsultationStore',
    errorMessage:
      'useCreateVideoConsultationContext must be used within a CreateVideoConsultationProvider',
    isDevelopment: import.meta.env.DEV,
    allowMissingProvider: false,
  })
