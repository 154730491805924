import type { StoreApi } from 'zustand'
import type { CancelConsultationStore } from './store'

import { createStrictContext } from '@fysioscout/utils/react'

interface CancelConsultationContextValue {
  store: StoreApi<CancelConsultationStore>
}

export const [CancelConsultationContext, useCancelConsultationContext] =
  createStrictContext<CancelConsultationContextValue>({
    name: 'CancelConsultationContext',
    errorMessage:
      'useCancelConsultationContext must be used within a CancelConsultationContext.Provider.',
    isDevelopment: import.meta.env.DEV,
    allowMissingProvider: false,
  })
