import { useSuspenseQuery } from '@tanstack/react-query'
import { useTitle } from 'ahooks'
import { PlusIcon } from 'lucide-react'

import { Button } from '@fysioscout/ui/buttons/button'

import { clinicPatientQueries } from '@/api/fysioscout/endpoints/clinic-patient/queries'
import { clinicTreatmentQueries } from '@/api/fysioscout/endpoints/clinic-treatment/queries'
import { clinicVideoConsultationsQueries } from '@/api/fysioscout/endpoints/clinic-video-consultations/queries'
import { Route } from '@/app/routes/_auth/patients/$patientId/index'
import { PageHeading, PageLayout } from '@/components/layouts/page-layout'
import { PatientTopBar } from '@/features/patients/components/patient-top-bar'
import { DetachPatientProvider } from '@/features/patients/user-actions/detach-patient/components/detach-patient-provider'
import { TreatmentList } from '@/features/treatments/components/treatment/treatment-list'
import { useCreateTreatmentActions } from '@/features/treatments/user-actions/create-treatment/store'
import { EditTreatmentNameProvider } from '@/features/treatments/user-actions/edit-treatment-name/components/edit-treatment-name-provider'
import { ScheduledConsultations } from '@/features/video-consultation/components/scheduled-consultations'

export function PatientView() {
  const navigate = Route.useNavigate()
  const params = Route.useParams()

  const { data: treatments } = useSuspenseQuery(
    clinicTreatmentQueries.treatments({ path: { patientId: params.patientId } }),
  )
  const { data: patient } = useSuspenseQuery(clinicPatientQueries.getById(params.patientId))
  const { data: scheduledConsultations } = useSuspenseQuery(
    clinicVideoConsultationsQueries.byPatientId({
      path: { id: params.patientId },
      query: { status: 'scheduled' },
    }),
  )

  useTitle(patient.name ?? patient.email)

  const handleDetachPatientSuccess = async () => {
    await navigate({ to: '/patients', search: { status: 'accepted' } })
  }

  return (
    <DetachPatientProvider onSuccess={handleDetachPatientSuccess}>
      <EditTreatmentNameProvider patientId={params.patientId}>
        <PageLayout>
          <div className={'stack gap-8'}>
            <PageHeading>{patient.name}</PageHeading>
            <PatientTopBar patient={patient} />
            <Controls />
            <TreatmentList treatments={treatments} patient={patient} />
            <ScheduledConsultations consultations={scheduledConsultations} />
          </div>
        </PageLayout>
      </EditTreatmentNameProvider>
    </DetachPatientProvider>
  )
}

function Controls() {
  const createTreatmentActions = useCreateTreatmentActions()

  return (
    <div className={'stack items-end justify-between'}>
      <Button
        variant={'solid'}
        color={'accent'}
        iconEnd={<PlusIcon />}
        onPress={() => {
          createTreatmentActions.open()
        }}
      >
        Opret behandlingsforløb
      </Button>
    </div>
  )
}
