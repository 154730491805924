import React from 'react'
import { Link } from '@tanstack/react-router'

import { Cell } from '@fysioscout/ui/collections/table'
import { UnstyledLink } from '@fysioscout/ui/navigation/unstyled-link'
import { Text } from '@fysioscout/ui/typography/text'

import { useConsultationTableRowContext } from '@/features/video-consultation/contexts/consultation-table-row-context'

export function PatientCell() {
  const { consultation } = useConsultationTableRowContext()

  return (
    <Cell textValue={consultation.patient.name}>
      <UnstyledLink>
        <Link
          to={'/video-consultation/patient/$patientId'}
          className={'hover:text-accent-11 stack'}
          params={{ patientId: consultation.patient.id }}
          search
        >
          <Text>{consultation.patient.name}</Text>
          <Text size={'1'} subtle>
            {consultation.patient.email}
          </Text>
        </Link>
      </UnstyledLink>
    </Cell>
  )
}
