import React from 'react'

import type { Schemas } from '@fysioscout/fysioscout-js/type-helpers'

import {
  Classification,
  Consent,
  Panel,
  PanelHeader,
  PanelItem,
  PanelItemDescription,
  PanelItemDescriptionList,
  PanelItemLabel,
} from './shared'

interface FollowUpConsultationProps {
  transcription: Schemas['FollowUpConsultation']
}

export function FollowUpConsultation(props: FollowUpConsultationProps) {
  return (
    <div data-testid={'follow-up-consultation'} className={'stack gap-6'}>
      <Classification type={'follow_up'} classification={props.transcription.classification} />
      <ExaminationAndTreatment
        examinationAndTreatment={props.transcription.examinationAndTreatment}
      />
      <Consent consent={props.transcription.consent} />
    </div>
  )
}

interface ExaminationAndTreatmentProps {
  examinationAndTreatment: Schemas['FollowUpExaminationAndTreatment']
}

function ExaminationAndTreatment(props: ExaminationAndTreatmentProps) {
  return (
    <Panel
      left={
        <PanelHeader
          heading={'Examination og behandling'}
          description={
            'Opfølgende examination og behandling dokumenterer patientens fremskridt, nye fund og den videre behandlingsplan.'
          }
        />
      }
      right={
        <>
          <PanelItem>
            <PanelItemLabel>Reaktion på forrige behandling</PanelItemLabel>

            <PanelItemDescription>
              {props.examinationAndTreatment.previousTreatmentResponse}
            </PanelItemDescription>
          </PanelItem>

          <PanelItem>
            <PanelItemLabel>Status</PanelItemLabel>

            <PanelItemDescription>
              {props.examinationAndTreatment.status.patientFeedback}
            </PanelItemDescription>
          </PanelItem>

          <PanelItem>
            <PanelItemLabel>Monitorering</PanelItemLabel>

            <PanelItemDescriptionList>
              <PanelItemDescription>
                Funktionel score: {props.examinationAndTreatment.monitoring.functionalScore}
              </PanelItemDescription>

              <PanelItemDescription>
                Smerteskala: {props.examinationAndTreatment.monitoring.painScale}
              </PanelItemDescription>
            </PanelItemDescriptionList>
          </PanelItem>

          <PanelItem>
            <PanelItemLabel>Nye undersøgelsesfund</PanelItemLabel>

            <PanelItemDescription>
              {props.examinationAndTreatment.newExaminationFindings}
            </PanelItemDescription>
          </PanelItem>

          <PanelItem>
            <PanelItemLabel>Behandling og plan</PanelItemLabel>

            <PanelItemDescriptionList>
              <PanelItemDescription>
                Nuværende behandling:{' '}
                {props.examinationAndTreatment.treatmentAndPlan.currentTreatment}
              </PanelItemDescription>

              <PanelItemDescription>
                Opfølgningsplan: {props.examinationAndTreatment.treatmentAndPlan.followUpSchedule}
              </PanelItemDescription>

              <PanelItemDescription>
                Supplerende anbefalinger:{' '}
                {props.examinationAndTreatment.treatmentAndPlan.supplementaryRecommendations}
              </PanelItemDescription>
            </PanelItemDescriptionList>
          </PanelItem>
        </>
      }
    />
  )
}
