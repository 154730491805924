'use client'

import { Separator as RACSeparator } from 'react-aria-components'
import { tv } from 'tailwind-variants'

import type { SeparatorProps as RACSeparatorProps } from 'react-aria-components'

const separatorStyles = tv({
  base: 'bg-border-neutral border-none',
  variants: {
    orientation: {
      horizontal: 'my-1 h-px w-full',
      vertical: 'mx-1 h-full w-px',
    },
  },
  defaultVariants: {
    orientation: 'horizontal',
  },
})

export type SeparatorProps = RACSeparatorProps

export function Separator({ orientation, className, ...rest }: SeparatorProps) {
  return <RACSeparator className={separatorStyles({ orientation, className })} {...rest} />
}
