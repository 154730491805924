import { createStore, useStore } from 'zustand'

import type { Schemas } from '@fysioscout/fysioscout-js/type-helpers'

import { useCreateVideoConsultationContext } from './context'

type EmployeeId = Schemas['ClinicEmployeesDetails']['id']
type PatientId = Schemas['PatientWithEmployee']['id']

export interface CreateVideoConsultationStore {
  isOpen: boolean
  employeeId: EmployeeId | null
  patientId: PatientId | null

  actions: {
    open: () => void
    close: () => void
    onOpenChange: (isOpen: boolean) => void

    setEmployeeId: (employeeId: EmployeeId | null) => void
    setPatientId: (patientId: PatientId | null) => void

    reset: () => void
  }
}

export type InitialState = Omit<CreateVideoConsultationStore, 'actions'>

export const INITIAL_STATE = {
  isOpen: false,
  employeeId: null,
  patientId: null,
} satisfies InitialState

export function createVideoConsultationStore(initialState: InitialState = INITIAL_STATE) {
  return createStore<CreateVideoConsultationStore>((set) => {
    return {
      ...initialState,
      actions: {
        open: () => {
          set({ isOpen: true })
        },
        close: () => {
          set({ isOpen: false })
        },
        onOpenChange: (isOpen) => {
          set({ isOpen })
        },
        setEmployeeId: (employeeId) => {
          set({ employeeId })
        },
        setPatientId: (patientId) => {
          set({ patientId })
        },
        reset: () => {
          set(INITIAL_STATE)
        },
      },
    }
  })
}

function useCreateVideoConsultationStore<T>(selector: (state: CreateVideoConsultationStore) => T) {
  const { store } = useCreateVideoConsultationContext()

  return useStore(store, selector)
}

export function useIsOpen() {
  return useCreateVideoConsultationStore((state) => state.isOpen)
}

export function useEmployeeId() {
  return useCreateVideoConsultationStore((state) => state.employeeId)
}

export function usePatientId() {
  return useCreateVideoConsultationStore((state) => state.patientId)
}

export function useCreateVideoConsultationActions() {
  return useCreateVideoConsultationStore((state) => state.actions)
}
