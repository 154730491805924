import React from 'react'
import { useSuspenseQuery } from '@tanstack/react-query'
import { CheckIcon, CopyIcon, NotebookIcon } from 'lucide-react'

import { Button } from '@fysioscout/ui/buttons/button'
import {
  CollapsiblePanel,
  CollapsiblePanelContent,
  CollapsiblePanelTrigger,
} from '@fysioscout/ui/layout/collapsible-panel'
import { Panel } from '@fysioscout/ui/layout/panel'
import { Text } from '@fysioscout/ui/typography/text'

import { clinicNotebookQueries } from '@/api/fysioscout/endpoints/clinic-notebook/queries'
import { useTreatmentContext } from '@/features/treatments/contexts/treatment-context'
import { useCopyToClipboardState } from '@/hooks/use-copy-to-clipboard-state'
import { dayjs } from '@/lib/dayjs'

export function NotebookOverview() {
  const { treatmentId } = useTreatmentContext()
  const { data: notebooks } = useSuspenseQuery(clinicNotebookQueries.listNotebooks({ treatmentId }))

  return (
    <div data-testid={'notebook-overview'}>
      <div className={'stack gap-3'}>
        {notebooks.length === 0 ? (
          <Panel isEmpty>
            <NotebookIcon />
            <Text>Ingen notater fundet.</Text>
          </Panel>
        ) : (
          notebooks.map(({ date, notes }) => (
            <CollapsiblePanel key={date}>
              <CollapsiblePanelTrigger>
                {dayjs.format(date, { dateStyle: 'full' })}
              </CollapsiblePanelTrigger>

              <CollapsiblePanelContent>
                <div className={'stack gap-4 p-4'}>
                  {notes.map((note) => (
                    <Note key={note.id} text={note.note ?? ''} />
                  ))}
                </div>
              </CollapsiblePanelContent>
            </CollapsiblePanel>
          ))
        )}
      </div>
    </div>
  )
}

interface NotebookOverviewProps {
  text: string
}

function Note({ text }: NotebookOverviewProps) {
  const { copy, copyStatus } = useCopyToClipboardState()

  const handleCopy = async () => {
    await copy(text)
  }

  return (
    <div
      className={
        'bg-neutral-2 border-border-neutral hstack min-h-28 items-start justify-between gap-24 rounded border p-4'
      }
    >
      <div className={'w-full'}>
        <Text size={'2'}>{text}</Text>
      </div>

      <Button
        variant={'outline'}
        size={'xs'}
        color={'neutral'}
        iconStart={copyStatus === 'copied' ? <CheckIcon /> : <CopyIcon />}
        onPress={handleCopy}
      >
        Kopiér
      </Button>
    </div>
  )
}
