import React from 'react'
import { Slot } from '@radix-ui/react-slot'
import { Link, createFileRoute, redirect } from '@tanstack/react-router'
import { useTitle } from 'ahooks'
import { BookIcon, LayoutListIcon, SquareUserIcon, VideoIcon } from 'lucide-react'

import type { LinkProps } from '@tanstack/react-router'

import { Heading } from '@fysioscout/ui/typography/heading'
import { Text } from '@fysioscout/ui/typography/text'
import { cn } from '@fysioscout/ui/utils'

import { PageLayout } from '@/components/layouts/page-layout'

export const Route = createFileRoute('/_auth/')({
  beforeLoad: () => {
    throw redirect({ to: '/patients', search: { status: 'accepted' } })
  },
  component: FrontpageView,
})

export function FrontpageView() {
  useTitle('Dashboard')

  return (
    <PageLayout>
      <div className={'stack gap-6'}>
        <Heading size={'8'} level={1}>
          Velkommen
        </Heading>

        <Text size={'4'}>
          Aute adipisicing velit quis cupidatat eiusmod est sint id sint excepteur enim cillum.
          Minim id sunt adipisicing fugiat tempor. Officia aute qui est Lorem veniam aliquip et
          enim.
        </Text>
      </div>

      <div className={'mt-10 grid grid-cols-2 gap-4 xl:grid-cols-4 xl:gap-5'}>
        <Card
          to={'/patients'}
          search={{ status: 'accepted' }}
          title={'Patienter'}
          icon={<SquareUserIcon />}
        >
          <div className={'stack'}>
            <Text size={'2'} muted>
              Antal patienter
            </Text>

            <Text size={'4'}>54</Text>
          </div>
        </Card>

        <Card isDisabled to={'/templates'} title={'Skabeloner'} icon={<BookIcon />}>
          <Text size={'2'}>Denne funktion er midlertidigt kun tilgængelig i appen.</Text>
        </Card>

        <Card isDisabled to={'/practitioners'} title={'Behandlere'} icon={<LayoutListIcon />}>
          <Text size={'2'}>Denne funktion er midlertidigt kun tilgængelig i appen.</Text>
        </Card>

        <Card isDisabled to={'/video-consultation'} title={'Patienter'} icon={<VideoIcon />}>
          <Text size={'2'}>Denne funktion er midlertidigt kun tilgængelig i appen.</Text>
        </Card>
      </div>
    </PageLayout>
  )
}

interface CardProps extends LinkProps {
  /** The child components to render within the Card. */
  children?: React.ReactNode

  /** The title of the Card. */
  title: string

  /** The icon to display on the Card. */
  icon: React.ReactNode

  /** If true, the Card will be disabled and not respond to user interactions. */
  isDisabled?: boolean
}

function Card({ children, title, icon, isDisabled, ...rest }: CardProps) {
  return (
    <Link
      className={cn(
        'border-surface-border bg-surface hover:bg-surface-hover min-h-64 rounded-md border p-6 transition-colors duration-200',
        {
          'pointer-events-none opacity-40': isDisabled,
        },
      )}
      {...rest}
    >
      <div className={'hstack items-center gap-3'}>
        <div className={'bg-neutral-5 rounded p-1.5'}>
          <Slot className={'text-muted-foreground size-5'}>{icon}</Slot>
        </div>

        <Text size={'3'}>{title}</Text>
      </div>

      <div className={'mt-8'}>{children}</div>
    </Link>
  )
}
