'use client'

import React from 'react'
import { XIcon } from 'lucide-react'
import {
  Button as RACButton,
  Tag as RACTag,
  TagGroup as RACTagGroup,
  TagList as RACTagList,
  Text as RACText,
  composeRenderProps,
} from 'react-aria-components'
import { twMerge } from 'tailwind-merge'
import { tv } from 'tailwind-variants'

import type {
  TagGroupProps as RACTagGroupProps,
  TagListProps as RACTagListProps,
  TagProps as RACTagProps,
} from 'react-aria-components'

import { Description, Label } from '../../forms/field/field'
import { focusRing } from '../../utils'

const colors = {
  gray: 'bg-neutral-4 text-neutral-11 border-neutral-6 hover:border-neutral-4',
  green: 'bg-green-2 text-green-11 border-green-3 hover:border-green-4',
  yellow: 'bg-yellow-2 text-yellow-11 border-yellow-3 hover:border-yellow-4',
  blue: 'bg-blue-100 text-info-11 border-blue-200 hover:border-blue-300',
}

type Color = keyof typeof colors
const ColorContext = React.createContext<Color>('gray')

const tagStyles = tv({
  extend: focusRing,
  base: 'flex max-w-fit cursor-default items-center gap-1 rounded-full border px-3 py-0.5 text-xs transition',
  variants: {
    color: {
      gray: '',
      green: '',
      yellow: '',
      blue: '',
    },
    allowsRemoving: {
      true: 'pr-1',
    },
    isSelected: {
      true: 'bg-neutral-9 border-transparent text-white forced-color-adjust-none',
    },
    isDisabled: {
      true: 'bg-disabled text-disabled-foreground',
    },
  },
  compoundVariants: (Object.keys(colors) as Color[]).map((color) => ({
    isSelected: false,
    color,
    class: colors[color],
  })),
})

export interface TagGroupProps<T>
  extends Omit<RACTagGroupProps, 'children'>,
    Pick<RACTagListProps<T>, 'items' | 'children' | 'renderEmptyState'> {
  color?: Color
  label?: string
  description?: string
  errorMessage?: string
}

export interface TagProps extends RACTagProps {
  color?: Color
}

export function TagGroup<T extends object>({
  label,
  description,
  errorMessage,
  items,
  children,
  renderEmptyState,
  ...props
}: TagGroupProps<T>) {
  return (
    <RACTagGroup {...props} className={twMerge('flex flex-col gap-1', props.className)}>
      <Label>{label}</Label>

      <ColorContext.Provider value={props.color ?? 'gray'}>
        <RACTagList
          items={items}
          renderEmptyState={renderEmptyState}
          className={'flex flex-wrap gap-x-1 gap-y-1.5'}
        >
          {children}
        </RACTagList>
      </ColorContext.Provider>

      {description ? <Description>{description}</Description> : null}

      {errorMessage ? (
        <RACText slot={'errorMessage'} className={'text-red text-sm'}>
          {errorMessage}
        </RACText>
      ) : null}
    </RACTagGroup>
  )
}

const removeButtonStyles = tv({
  extend: focusRing,
  base: 'pressed:bg-blackA-4 hover:bg-blackA-3 flex cursor-default items-center justify-center rounded-full p-0.5 transition-colors',
})

export function Tag({ children, color, ...props }: TagProps) {
  const textValue = typeof children === 'string' ? children : undefined
  const groupColor = React.useContext(ColorContext)

  return (
    <RACTag
      textValue={textValue}
      {...props}
      className={composeRenderProps(props.className, (className, renderProps) =>
        tagStyles({ ...renderProps, className, color: color ?? groupColor }),
      )}
    >
      {({ allowsRemoving }) => (
        <>
          {children}

          {allowsRemoving ? (
            <RACButton slot={'remove'} className={removeButtonStyles}>
              <XIcon aria-hidden className={'size-3'} />
            </RACButton>
          ) : null}
        </>
      )}
    </RACTag>
  )
}
