'use client'

import { Check, Minus } from 'lucide-react'
import { Checkbox as RACCheckbox, composeRenderProps } from 'react-aria-components'
import { tv } from 'tailwind-variants'

import type { CheckboxProps as RACCheckboxProps } from 'react-aria-components'

import { focusRing } from '../../utils'

const checkboxStyles = tv({
  base: 'text-foreground group flex items-center gap-2 text-sm transition',
  variants: {
    isInvalid: {
      true: 'text-red-9',
    },
    isDisabled: {
      true: 'text-disabled-foreground',
    },
  },
})

const boxStyles = tv({
  extend: focusRing,
  base: 'flex size-5 flex-shrink-0 items-center justify-center rounded border transition',
  variants: {
    isSelected: {
      false: 'border-neutral-6 bg-field group-hover:border-neutral-7',
      true: 'bg-accent-9 border-accent-9 group-hover:bg-accent-10 group-hover:border-accent-10',
    },
    isInvalid: {
      true: 'border-red-9 group-hover:border-red-10 group-selected:bg-red-9',
    },
    isDisabled: {
      true: 'border-neutral-6 group-selected:bg-transparent pointer-events-none',
    },
  },
})

const iconStyles = 'size-4 text-accent-contrast group-disabled:text-disabled-foreground'

export type CheckboxProps = RACCheckboxProps

export function Checkbox(props: CheckboxProps) {
  return (
    <RACCheckbox
      {...props}
      className={composeRenderProps(props.className, (className, renderProps) =>
        checkboxStyles({ ...renderProps, className }),
      )}
    >
      {({ isSelected, isIndeterminate, ...renderProps }) => (
        <>
          <div className={boxStyles({ isSelected: isSelected || isIndeterminate, ...renderProps })}>
            {isIndeterminate ? (
              <Minus aria-hidden className={iconStyles} />
            ) : isSelected ? (
              <Check aria-hidden className={iconStyles} />
            ) : null}
          </div>

          {props.children}
        </>
      )}
    </RACCheckbox>
  )
}
