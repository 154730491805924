import { Time, now } from '@internationalized/date'
import { pipe } from 'remeda'

import type { CalendarDate } from '@internationalized/date'

import { DEFAULT_TIME_ZONE } from '@fysioscout/env/date-time'

import { dayjs } from '@/lib/dayjs'

export function getDefaultTimeValue(): Time {
  const currentTime = now(DEFAULT_TIME_ZONE)
  const currentTimePlusOneHour = new Time(currentTime.hour, currentTime.minute).add({ hours: 1 })

  return pipe(currentTimePlusOneHour, roundToNearest5Minutes)
}

export function roundToNearest5Minutes(time: Time): Time {
  const minutes = Math.round(time.minute / 5) * 5

  return new Time(time.hour, minutes === 60 ? 0 : minutes, 0, time.millisecond)
}

/**
 * Creates a schedule date from a date and time object.
 *
 * @param date - The date object.
 * @param time - The time object.
 * @returns The schedule date as a normalized ISO string (utc).
 */
export function createScheduleDate(date: CalendarDate, time: Time) {
  const dateString = date.toString()

  return dayjs
    .instance(dateString)
    .set('hour', time.hour)
    .set('minute', time.minute)
    .utc()
    .toISOString()
}

/**
 * Calculate the time difference in minutes between the scheduled date and the current time.
 *
 * @param targetDate - The scheduled date as a string or Date object.
 * @returns The time difference in whole minutes.
 */
export function getTimeDifferenceMinutes(targetDate: string | Date) {
  const target = dayjs.instance(targetDate)
  const now = dayjs.instance().startOf('minute')

  return target.diff(now, 'minutes')
}
