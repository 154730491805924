import React from 'react'

import { ResendConsultationEmailDialog } from './resend-consultation-email-dialog'
import { ResendConsultationEmailProvider } from './resend-consultation-email-provider'

interface ResendConsultationEmailProps {
  children?: React.ReactNode
}

export function ResendConsultationEmail({ children }: ResendConsultationEmailProps) {
  return (
    <ResendConsultationEmailProvider>
      <ResendConsultationEmailDialog />

      {children}
    </ResendConsultationEmailProvider>
  )
}
