import { createStore, useStore } from 'zustand'

import type { Schemas } from '@fysioscout/fysioscout-js/type-helpers'

import { useCancelConsultationContext } from './context'

type ConsultationId = Schemas['ConsultationResponseDto']['id']

export interface CancelConsultationStore {
  consultationId: ConsultationId | null
  actions: {
    setConsultationId: (consultationId: ConsultationId | null) => void
    onOpenChange: (isOpen: boolean) => void
    reset: () => void
  }
}

export type InitialState = Omit<CancelConsultationStore, 'actions'>

const INITIAL_STATE = {
  consultationId: null,
}

export function createCancelConsultationStore(initialState: InitialState = INITIAL_STATE) {
  return createStore<CancelConsultationStore>((set) => ({
    ...initialState,
    actions: {
      setConsultationId: (consultationId) => {
        set({ consultationId })
      },
      onOpenChange: (isOpen) => {
        set({ consultationId: isOpen ? initialState.consultationId : null })
      },
      reset: () => {
        set(INITIAL_STATE)
      },
    },
  }))
}

function useCancelConsultationStore<T>(selector: (state: CancelConsultationStore) => T) {
  const { store } = useCancelConsultationContext()

  return useStore(store, selector)
}

export function useConsultationId() {
  return useCancelConsultationStore((state) => state.consultationId)
}

export function useIsOpen() {
  return useCancelConsultationStore((state) => Boolean(state.consultationId))
}

export function useCancelConsultationActions() {
  return useCancelConsultationStore((state) => state.actions)
}
