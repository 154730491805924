import React from 'react'
import { Link } from '@tanstack/react-router'
import { FileTextIcon } from 'lucide-react'
import { TooltipTrigger } from 'react-aria-components'

import { LinkButton } from '@fysioscout/ui/buttons/link-button'
import { Cell } from '@fysioscout/ui/collections/table'
import { Tooltip } from '@fysioscout/ui/overlays/tooltip'

import { useConsultationTableRowContext } from '@/features/video-consultation/contexts/consultation-table-row-context'

export function TranscriptionCell() {
  const { consultation } = useConsultationTableRowContext()

  const isDisabled = !consultation.has_transcription

  const tooltip = consultation.has_transcription
    ? ''
    : 'Transskribering var ikke aktiveret på tidspunktet for videokonsultationen. '

  return (
    <Cell>
      <TooltipTrigger delay={100} closeDelay={100} isDisabled={!isDisabled}>
        <LinkButton
          variant={'soft'}
          size={'sm'}
          color={'neutral'}
          iconStart={<FileTextIcon />}
          isVisuallyDisabled={isDisabled}
        >
          <Link
            to={'/video-consultation/patient/$patientId/consultation/$consultationId/transcription'}
            params={{ patientId: consultation.patient.id, consultationId: consultation.id }}
            search
            disabled={isDisabled}
          >
            Transskribering
          </Link>
        </LinkButton>

        <Tooltip>{tooltip}</Tooltip>
      </TooltipTrigger>
    </Cell>
  )
}
