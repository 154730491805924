import React from 'react'
import { useNavigate, useRouter } from '@tanstack/react-router'
import { BanIcon, MailQuestionIcon, PencilIcon, VideoIcon } from 'lucide-react'
import { MenuTrigger } from 'react-aria-components'

import { Menu, MenuItem } from '@fysioscout/ui/collections/menu'
import { Cell, TableActionsTrigger } from '@fysioscout/ui/collections/table'
import { useHoverTimer } from '@fysioscout/utils/react'

import { useConsultationTableRowContext } from '@/features/video-consultation/contexts/consultation-table-row-context'
import { useCancelConsultationActions } from '@/features/video-consultation/user-actions/cancel-consultation/store'
import { useEditConsultationActions } from '@/features/video-consultation/user-actions/edit-consultation/store'
import { useResendConsultationEmailActions } from '@/features/video-consultation/user-actions/resend-consultation-email/store'

type Action =
  | 'view-all-consultations'
  | 'resend-email'
  | 'cancel-consultation'
  | 'edit-consultation'

interface ActionsCellProps {
  /** Array of actions that should be disabled/unavailable */
  disabledActions?: Action[]
}

export function ActionsCell({ disabledActions }: ActionsCellProps) {
  const { consultation } = useConsultationTableRowContext()

  const navigate = useNavigate()
  const router = useRouter()

  const cancelConsultationActions = useCancelConsultationActions()
  const resendEmailActions = useResendConsultationEmailActions()
  const editConsultationActions = useEditConsultationActions()

  const preloadPatientView = useHoverTimer(() => {
    void router.preloadRoute({
      to: '/video-consultation/patient/$patientId',
      params: { patientId: consultation.patient.id },
    })
  })

  const handleViewAllConsultations = async () => {
    await navigate({
      to: '/video-consultation/patient/$patientId',
      params: { patientId: consultation.patient.id },
    })
  }

  const handleResendEmail = () => {
    resendEmailActions.setConsultationId(consultation.id)
  }

  const handleCancelConsultation = () => {
    cancelConsultationActions.setConsultationId(consultation.id)
  }

  const handleEditConsultation = () => {
    editConsultationActions.setConsultationId(consultation.id)
    editConsultationActions.setIsOpen(true)
  }

  const isViewAllConsultationsDisabled = disabledActions?.includes('view-all-consultations')
  const isEditConsultationDisabled = disabledActions?.includes('edit-consultation')
  const isResendEmailDisabled = disabledActions?.includes('resend-email')
  const isCancelConsultationDisabled = disabledActions?.includes('cancel-consultation')

  return (
    <Cell>
      <MenuTrigger>
        <TableActionsTrigger />

        <Menu selectionMode={'none'} disallowEmptySelection placement={'bottom end'}>
          {isViewAllConsultationsDisabled ? null : (
            <MenuItem
              id={'view-all-consultations'}
              onAction={handleViewAllConsultations}
              icon={<VideoIcon />}
              onHoverStart={preloadPatientView.start}
              onHoverEnd={preloadPatientView.end}
            >
              Alle konsultationer
            </MenuItem>
          )}

          {isEditConsultationDisabled ? null : (
            <MenuItem
              id={'edit-consultation'}
              onAction={handleEditConsultation}
              icon={<PencilIcon />}
            >
              Rediger
            </MenuItem>
          )}

          {isResendEmailDisabled ? null : (
            <MenuItem id={'resend-email'} onAction={handleResendEmail} icon={<MailQuestionIcon />}>
              Gensend link
            </MenuItem>
          )}

          {isCancelConsultationDisabled ? null : (
            <MenuItem
              id={'cancel-consultation'}
              onAction={handleCancelConsultation}
              icon={<BanIcon />}
            >
              Aflys konsultation
            </MenuItem>
          )}
        </Menu>
      </MenuTrigger>
    </Cell>
  )
}
