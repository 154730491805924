import type { Schemas } from '@fysioscout/fysioscout-js/type-helpers'

import { createStrictContext } from '@fysioscout/utils/react'

interface ConsultationTableRowValue {
  consultation: Schemas['ConsultationResponseDto']
}

export const [ConsultationTableRowContext, useConsultationTableRowContext] =
  createStrictContext<ConsultationTableRowValue>({
    name: 'ConsultationTableRowContext',
    errorMessage:
      'useConsultationTableRowContext must be used within a ConsultationTableRowContext.Provider.',
    isDevelopment: import.meta.env.DEV,
    allowMissingProvider: false,
  })
