import React from 'react'
import { Outlet } from '@tanstack/react-router'

import { ConsultationActionsProvider } from '@/features/video-consultation/components/consultation-actions-provider'

export function VideoConsultationRoute() {
  return (
    <ConsultationActionsProvider>
      <Outlet />
    </ConsultationActionsProvider>
  )
}
