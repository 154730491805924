import { useMutation } from '@tanstack/react-query'

import { toast } from '@fysioscout/ui/status/toast'

import { clinicVideoConsultationMutations } from '@/api/fysioscout/endpoints/clinic-video-consultations/mutations'

/**
 * Resend email to a video consultation.
 *
 * @example
 *   const { mutate, isLoading, isError } = useResendEmail()
 *   mutate(resendEmailOptions)
 *
 * @returns - The result of the mutation.
 */
export function useResendEmail() {
  return useMutation({
    mutationFn: clinicVideoConsultationMutations.resendConsultationEmail,
    onSuccess: () => {
      toast.success('Email til patienten blev sendt.')
    },
  })
}
