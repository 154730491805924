import React from 'react'

import { Drawer } from '@fysioscout/ui/overlays/drawer'

import { useEditConsultationActions, useIsOpen } from '../store'
import { EditConsultationDialog } from './edit-consultation-dialog'

export function EditConsultationDrawer() {
  const actions = useEditConsultationActions()
  const isOpen = useIsOpen()

  return (
    <Drawer isOpen={isOpen} onOpenChange={actions.setIsOpen} size={'2'}>
      <EditConsultationDialog />
    </Drawer>
  )
}
