import React from 'react'
import { useSuspenseQuery } from '@tanstack/react-query'
import { Link } from '@tanstack/react-router'
import { useTitle } from 'ahooks'
import { PlusIcon } from 'lucide-react'

import { Button } from '@fysioscout/ui/buttons/button'
import { LinkButton } from '@fysioscout/ui/buttons/link-button'
import { Label } from '@fysioscout/ui/forms/field'
import { Link as UILink } from '@fysioscout/ui/navigation/link'
import { Text } from '@fysioscout/ui/typography/text'

import { clinicPatientQueries } from '@/api/fysioscout/endpoints/clinic-patient/queries'
import { clinicVideoConsultationsQueries } from '@/api/fysioscout/endpoints/clinic-video-consultations/queries'
import { PageHeading, PageLayout } from '@/components/layouts/page-layout'
import { FinishedConsultations } from '@/features/video-consultation/components/finished-consultations'
import { ScheduledConsultations } from '@/features/video-consultation/components/scheduled-consultations'
import { useCreateVideoConsultationActions } from '@/features/video-consultation/user-actions/create-video-consultation/store'

import { Route } from './index'

export function PatientVideoConsultationView() {
  const params = Route.useParams()
  const search = Route.useSearch()

  const { data: patient } = useSuspenseQuery(clinicPatientQueries.getById(params.patientId))
  const { data: consultations } = useSuspenseQuery(
    clinicVideoConsultationsQueries.byPatientId({
      path: { id: params.patientId },
      query: { status: search.status },
    }),
  )

  useTitle(patient.name ?? patient.email)

  return (
    <PageLayout data-testid={'patient-video-consultation-view'}>
      <div className={'stack gap-8'}>
        <Header />
        <Information />
        <Controls />

        {search.status === 'scheduled' ? (
          <ScheduledConsultations consultations={consultations} />
        ) : (
          <FinishedConsultations consultations={consultations} />
        )}
      </div>
    </PageLayout>
  )
}

function Header() {
  const search = Route.useSearch()
  const params = Route.useParams()

  const { data: patient } = useSuspenseQuery(clinicPatientQueries.getById(params.patientId))

  return (
    <div className={'hstack items-end justify-between'}>
      <PageHeading>{patient.name}</PageHeading>

      <div className={'hstack gap-2'}>
        <LinkButton
          variant={'soft'}
          color={search.status === 'scheduled' ? 'accent' : 'neutral'}
          size={'sm'}
        >
          <Link
            from={Route.fullPath}
            to={'.'}
            search={(prev) => ({ ...prev, status: 'scheduled' })}
          >
            Planlagte
          </Link>
        </LinkButton>

        <LinkButton
          variant={'soft'}
          color={search.status === 'finished' ? 'accent' : 'neutral'}
          size={'sm'}
        >
          <Link from={Route.fullPath} to={'.'} search={(prev) => ({ ...prev, status: 'finished' })}>
            Afholdte
          </Link>
        </LinkButton>
      </div>
    </div>
  )
}

function Information() {
  const params = Route.useParams()

  const { data: patient } = useSuspenseQuery(clinicPatientQueries.getById(params.patientId))

  const emailLabelId = React.useId()
  const detailsLabelId = React.useId()

  return (
    <div className={'border-border-neutral border-y py-8'}>
      <div
        className={
          'hstack 3xl:gap-28 w-full items-center gap-16 overflow-x-scroll whitespace-nowrap text-sm'
        }
      >
        <div className={'stack gap-1'}>
          <Label id={emailLabelId}>Email</Label>
          <Text aria-labelledby={emailLabelId}>{patient.email}</Text>
        </div>

        <div className={'stack gap-1'}>
          <Label id={detailsLabelId}>Patientdetaljer</Label>

          <UILink aria-labelledby={detailsLabelId} intent={'forward'}>
            <Link
              from={Route.fullPath}
              to={'/patients/$patientId'}
              params={{ patientId: params.patientId }}
            >
              Gå til patientdetaljer
            </Link>
          </UILink>
        </div>
      </div>
    </div>
  )
}

function Controls() {
  const params = Route.useParams()
  const actions = useCreateVideoConsultationActions()

  const handlePress = () => {
    actions.setPatientId(params.patientId)
    actions.open()
  }

  return (
    <div className={'flex justify-end'}>
      <Button variant={'solid'} color={'accent'} iconEnd={<PlusIcon />} onPress={handlePress}>
        Opret videokonsultation
      </Button>
    </div>
  )
}
