import React from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { CalendarDate, Time, now, today } from '@internationalized/date'
import { useQuery } from '@tanstack/react-query'
import { useNavigate } from '@tanstack/react-router'
import { Controller, useForm } from 'react-hook-form'
import { z } from 'zod'

import type { ConfirmConsultationDialogProps } from '@/features/video-consultation/components/consultation-form/confirmation-dialogs'
import type { Schemas } from '@fysioscout/fysioscout-js/type-helpers'
import type { SubmitHandler } from 'react-hook-form'

import { DEFAULT_TIME_ZONE } from '@fysioscout/env/date-time'
import { Button } from '@fysioscout/ui/buttons/button'
import { DatePicker } from '@fysioscout/ui/date-and-time/date-picker'
import { TimeField } from '@fysioscout/ui/date-and-time/time-field'
import { Form } from '@fysioscout/ui/forms/form'
import { Switch } from '@fysioscout/ui/forms/switch'
import { ComboBox, ComboBoxItem } from '@fysioscout/ui/pickers/combo-box'
import { toast } from '@fysioscout/ui/status/toast'
import { Heading } from '@fysioscout/ui/typography/heading'
import { Text } from '@fysioscout/ui/typography/text'

import { clinicTreatmentQueries } from '@/api/fysioscout/endpoints/clinic-treatment/queries'
import { useAuthContext } from '@/features/auth/context'
import { useCreateConsultation } from '@/features/video-consultation/api/create-consultation'
import {
  ConfirmImmediateConsultationDialog,
  ConfirmPlannedConsultationDialog,
} from '@/features/video-consultation/components/consultation-form/confirmation-dialogs'
import { MINIMUM_SCHEDULING_NOTICE_MINUTES } from '@/features/video-consultation/config/constants'
import {
  createScheduleDate,
  getDefaultTimeValue,
  roundToNearest5Minutes,
} from '@/features/video-consultation/utils'
import { dayjs } from '@/lib/dayjs'

import { useCreateVideoConsultationContext } from '../context'
import { useCreateVideoConsultationActions } from '../store'

const BaseConsultationSchema = z.object({
  clinicEmployeeId: z.string().min(1, { message: 'Du mangler at vælge en behandler.' }),
  patientId: z.string().min(1, { message: 'Du mangler at vælge en patient.' }),
  treatmentId: z.string().nullable(),
})

const ImmediateConsultationSchema = BaseConsultationSchema.extend({
  type: z.literal('immediate'),
})

const PlannedConsultationSchema = BaseConsultationSchema.extend({
  type: z.literal('planned'),
  date: z
    .instanceof(CalendarDate, { message: 'Datoen er ikke gyldig.' })
    .nullable()
    .refine((date) => (date ? date.compare(today(DEFAULT_TIME_ZONE)) >= 0 : true), {
      message: 'Datoen kan ikke være før dagens dato.',
    }),
  time: z.instanceof(Time, { message: 'Tidspunktet er ikke gyldig.' }).nullable(),
})

const CreateVideoConsultationFormSchema = z
  .discriminatedUnion('type', [ImmediateConsultationSchema, PlannedConsultationSchema])
  .refine(
    (data) => {
      if (data.type === 'planned') {
        return data.date !== null
      }
      return true
    },
    {
      message: 'Du skal angive en gyldig dato.',
      path: ['date'],
    },
  )
  .refine(
    (data) => {
      if (data.type === 'planned') {
        return data.time !== null
      }
      return true
    },
    {
      message: 'Du skal angive et gyldigt tidspunkt.',
      path: ['time'],
    },
  )
  .refine(
    (data) => {
      if (data.type === 'planned' && data.date && data.time) {
        const currentDate = today(DEFAULT_TIME_ZONE)
        const currentTime = now(DEFAULT_TIME_ZONE)
        const minTime = currentTime.add({ minutes: MINIMUM_SCHEDULING_NOTICE_MINUTES })

        if (data.date.compare(currentDate) === 0) {
          return data.time.compare(minTime) >= 0
        }
      }
      return true
    },
    {
      message: `Tidspunktet skal være mindst ${MINIMUM_SCHEDULING_NOTICE_MINUTES} minutter frem i tiden.`,
      path: ['time'],
    },
  )

type FormSchema = z.infer<typeof CreateVideoConsultationFormSchema>

interface CreateVideoConsultationFormProps {
  employees: Schemas['ClinicEmployeesDetails'][]
  patients: Schemas['PatientWithEmployee'][]
  defaultEmployeeId?: Schemas['ClinicEmployeesDetails']['id']
  defaultPatientId?: Schemas['PatientWithEmployee']['id']
}

export function CreateVideoConsultationForm({
  employees,
  patients,
  defaultEmployeeId,
  defaultPatientId = '',
}: CreateVideoConsultationFormProps) {
  const [confirmationDialogOpen, setConfirmationDialogOpen] = React.useState(false)

  const actions = useCreateVideoConsultationActions()
  const authContext = useAuthContext()
  const context = useCreateVideoConsultationContext()
  const mutation = useCreateConsultation()
  const navigate = useNavigate()

  const form = useForm<FormSchema>({
    resolver: zodResolver(CreateVideoConsultationFormSchema),
    defaultValues: {
      type: 'immediate',
      clinicEmployeeId: defaultEmployeeId ?? context.defaultEmployeeId ?? '',
      patientId: defaultPatientId,
      treatmentId: null,
    },
  })

  const [
    selectedType,
    selectedPractitionerId,
    selectedPatientId,
    selectedTreatmentId,
    selectedDate,
    selectedTime,
  ] = form.watch(['type', 'clinicEmployeeId', 'patientId', 'treatmentId', 'date', 'time'])

  const handleTypeChange = (isPlanned: boolean) => {
    if (isPlanned) {
      form.setValue('type', 'planned')
      form.setValue('date', today(DEFAULT_TIME_ZONE))
      form.setValue('time', getDefaultTimeValue())
    } else {
      form.setValue('type', 'immediate')
      form.unregister(['date', 'time'])
    }
  }

  const handleFormSubmit: SubmitHandler<FormSchema> = () => setConfirmationDialogOpen(true)

  const handleMutation = () => {
    const { clinicEmployeeId, patientId, treatmentId, ...formValues } = form.getValues()

    const basePayload = {
      clinicEmployeeId,
      patientId,
      treatmentId,
    }

    const redirect = async () => {
      await navigate({
        to: '/video-consultation/patient/$patientId',
        params: { patientId },
      })

      setConfirmationDialogOpen(false)
      actions.reset()
    }

    if (formValues.type === 'immediate') {
      const scheduleDate = dayjs.instance().add(10, 'seconds').utc().toISOString()

      mutation.mutate(
        { ...basePayload, scheduleDate },
        {
          onSuccess: () => {
            toast.success('Videokonsultation oprettet')
            void redirect()
          },
        },
      )
    }

    if (formValues.type === 'planned' && formValues.date && formValues.time) {
      const { time, date } = formValues

      const scheduleDate = createScheduleDate(date, time)

      mutation.mutate(
        { ...basePayload, scheduleDate },
        {
          onSuccess: () => {
            toast.success('Videokonsultation oprettet', {
              description: `Planlagt til d. ${dayjs.format(scheduleDate, { dateStyle: 'full' })}.`,
            })

            void redirect()
          },
        },
      )
    }
  }

  const { data: treatments } = useQuery(
    clinicTreatmentQueries.treatments(
      { path: { patientId: selectedPatientId } },
      { enabled: Boolean(selectedPatientId) },
    ),
  )

  const selectedPractitioner = employees.find(({ id }) => id === selectedPractitionerId)
  const selectedPatient = patients.find(({ id }) => id === selectedPatientId)
  const selectedTreatment = treatments?.find(({ id }) => id === selectedTreatmentId)
  const isPlanning = selectedType === 'planned'

  const confirmationDialogProps = {
    isOpen: confirmationDialogOpen,
    isPending: mutation.isPending,
    onAction: handleMutation,
    onOpenChange: setConfirmationDialogOpen,
    practitionerName: selectedPractitioner?.name ?? '',
    patientName: selectedPatient?.name ?? '',
    treatmentName: selectedTreatment?.name ?? '',
    selectedDate: selectedDate,
    selectedTime: selectedTime,
    selectedSelf: selectedPractitioner?.user_id === authContext.user.id,
  } satisfies ConfirmConsultationDialogProps

  return (
    <>
      <Form onSubmit={form.handleSubmit(handleFormSubmit)}>
        <div className={'mb-4'}>
          <Heading level={1} slot={'title'} size={'5'} medium className={'mb-1'}>
            Opret ny videokonsultation
          </Heading>

          <Text size={'2'} subtle>
            Vælg behandler, patient og planlæg konsultationen.
          </Text>
        </div>

        <Controller
          control={form.control}
          name={'clinicEmployeeId'}
          render={({ field, fieldState }) => (
            <ComboBox
              {...field}
              label={'Behandler'}
              items={employees}
              selectedKey={field.value || undefined}
              onSelectionChange={(practitionerId) => {
                field.onChange(practitionerId ?? '')
              }}
              validationBehavior={'aria'}
              errorMessage={fieldState.error?.message}
              isInvalid={fieldState.invalid}
            >
              {(item) => <ComboBoxItem>{item.name}</ComboBoxItem>}
            </ComboBox>
          )}
        />

        <Controller
          control={form.control}
          name={'patientId'}
          render={({ field, fieldState }) => (
            <ComboBox
              {...field}
              label={'Patient'}
              items={patients}
              selectedKey={field.value || null}
              onSelectionChange={(patientId) => {
                field.onChange(patientId ?? '')
              }}
              validationBehavior={'aria'}
              errorMessage={fieldState.error?.message}
              isInvalid={fieldState.invalid}
              isReadOnly={patients.length === 0}
              description={patients.length === 0 ? `Ingen patienter fundet.` : undefined}
            >
              {(item) => (
                <ComboBoxItem
                  textValue={`${item.name}`}
                  className={'flex flex-col items-start gap-0.5'}
                >
                  <Text slot={'label'}>{item.name}</Text>
                  <Text slot={'description'} subtle>
                    {item.email}
                  </Text>
                </ComboBoxItem>
              )}
            </ComboBox>
          )}
        />

        <Controller
          control={form.control}
          name={'treatmentId'}
          render={({ field, fieldState }) => (
            <ComboBox
              {...field}
              label={'Behandlingsforløb (valgfri)'}
              items={treatments ?? []}
              selectedKey={field.value}
              onSelectionChange={(treatmentId) => {
                field.onChange(treatmentId)
              }}
              validationBehavior={'aria'}
              errorMessage={fieldState.error?.message}
              isInvalid={fieldState.invalid}
              isReadOnly={!treatments || treatments.length === 0}
              emptyCollectionLabel={'Ingen behandlingsforløb fundet.'}
              description={'Behandlingsforløb kan vælges efter valg af patient.'}
            >
              {(item) => <ComboBoxItem>{item.name}</ComboBoxItem>}
            </ComboBox>
          )}
        />

        <Controller
          control={form.control}
          name={'type'}
          render={({ field }) => (
            <Switch {...field} onChange={handleTypeChange} isSelected={field.value === 'planned'}>
              Opret til senere
            </Switch>
          )}
        />

        {isPlanning ? (
          <Controller
            control={form.control}
            name={'date'}
            render={({ field, fieldState }) => (
              <DatePicker
                {...field}
                label={'Dato'}
                onChange={(date) => field.onChange(date)}
                minValue={today(DEFAULT_TIME_ZONE)}
                errorMessage={fieldState.error?.message}
                isInvalid={fieldState.invalid}
                validationBehavior={'aria'}
              />
            )}
          />
        ) : null}

        {isPlanning ? (
          <Controller
            control={form.control}
            name={'time'}
            render={({ field, fieldState }) => (
              <TimeField
                {...field}
                label={'Tid'}
                hideTimeZone
                shouldForceLeadingZeros
                hourCycle={24}
                errorMessage={fieldState.error?.message}
                isInvalid={fieldState.invalid}
                validationBehavior={'aria'}
                onBlur={() => {
                  const time = field.value

                  if (time) {
                    field.onChange(roundToNearest5Minutes(time))
                  }
                }}
              />
            )}
          />
        ) : null}

        <Button
          type={'submit'}
          variant={'solid'}
          color={'accent'}
          size={'md'}
          className={'self-start'}
        >
          {selectedType === 'immediate' ? 'Start videokonsultation' : 'Opret videokonsultation'}
        </Button>
      </Form>

      {isPlanning ? (
        <ConfirmPlannedConsultationDialog {...confirmationDialogProps} />
      ) : (
        <ConfirmImmediateConsultationDialog {...confirmationDialogProps} />
      )}
    </>
  )
}
