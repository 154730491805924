import React from 'react'
import { useKeyPress } from 'ahooks'
import { CheckIcon, CopyIcon } from 'lucide-react'
import { useHover } from 'react-aria'
import { tv } from 'tailwind-variants'

import type { Schemas } from '@fysioscout/fysioscout-js/type-helpers'
import type { HeadingProps } from '@fysioscout/ui/typography/heading'
import type { TextProps } from '@fysioscout/ui/typography/text'

import { Button } from '@fysioscout/ui/buttons/button'
import { Panel as UIPanel } from '@fysioscout/ui/layout/panel'
import { toast } from '@fysioscout/ui/status/toast'
import { Heading } from '@fysioscout/ui/typography/heading'
import { Text } from '@fysioscout/ui/typography/text'

import { useCopyToClipboardState } from '@/hooks/use-copy-to-clipboard-state'

interface PanelProps {
  left: React.ReactNode
  right: React.ReactNode
  className?: string
}

const panelStyles = tv({
  base: 'border-border-neutral grid grid-cols-2 gap-0',
})

export function Panel({ left, right, className }: PanelProps) {
  return (
    <UIPanel className={panelStyles({ className })}>
      <div className={'stack max-w-prose gap-6 p-8'}>{left}</div>
      <div className={'border-border-neutral stack gap-6 border-l p-8'}>{right}</div>
    </UIPanel>
  )
}

interface PanelHeaderProps {
  heading: React.ReactNode | string
  description: React.ReactNode | string
}

export function PanelHeader({ heading, description }: PanelHeaderProps) {
  return (
    <div className={'stack gap-5'}>
      {typeof heading === 'string' ? <PanelHeading>{heading}</PanelHeading> : heading}
      {typeof description === 'string' ? (
        <PanelDescription>{description}</PanelDescription>
      ) : (
        description
      )}
    </div>
  )
}

export function PanelHeading({ children, ...rest }: HeadingProps) {
  return (
    <Heading size={'4'} medium {...rest}>
      {children}
    </Heading>
  )
}

export function PanelDescription({ children, ...rest }: TextProps) {
  return (
    <Text size={'2'} subtle {...rest}>
      {children}
    </Text>
  )
}

const COPY_STATUS_DURATION = 2000

export function PanelItem({ children, ...rest }: React.ComponentPropsWithoutRef<'dl'>) {
  const { copy, copyStatus } = useCopyToClipboardState({ timeoutDuration: COPY_STATUS_DURATION })
  const { isHovered, hoverProps } = useHover({})

  const copyContent = React.useCallback(() => {
    const childrenArray = React.Children.toArray(children)
    const descriptions: string[] = []

    for (const child of childrenArray) {
      if (!React.isValidElement(child)) continue

      if (child.type === PanelItemDescription) {
        const text = String(child.props.children)
        if (text) descriptions.push(text)
      }

      if (child.type === PanelItemDescriptionList) {
        React.Children.forEach(child.props.children, (listChild: unknown) => {
          if (
            React.isValidElement<{ children: React.ReactNode }>(listChild) &&
            listChild.type === PanelItemDescription
          ) {
            const text = String(listChild.props.children)
            if (text) descriptions.push(text)
          }
        })
      }
    }

    return descriptions.length > 1 ? descriptions.join('\n') : (descriptions[0] ?? '')
  }, [children])

  const handleCopy = React.useCallback(async () => {
    const text = copyContent()
    await copy(text)
    toast.success('Tekst kopieret til udklipsholderen.', { duration: COPY_STATUS_DURATION })
  }, [copy, copyContent])

  useKeyPress(['meta.c', 'ctrl.c'], () => {
    const selection = globalThis.getSelection()
    if (selection?.toString().trim() || !isHovered) return
    void handleCopy()
  })

  return (
    <div
      {...hoverProps}
      data-hovered={isHovered || undefined}
      className={
        'hstack hover:bg-surface-hover group -mx-4 -my-3 justify-between gap-4 rounded-md px-4 py-3'
      }
    >
      <dl className={'stack space-y-2'} {...rest}>
        {children}
      </dl>

      <div className={'opacity-0 group-hover:opacity-100'}>
        <Button variant={'transparent'} size={'sm'} color={'neutral'} onPress={handleCopy}>
          {copyStatus === 'copied' ? <CheckIcon /> : <CopyIcon />}
        </Button>
      </div>
    </div>
  )
}

export function PanelItemLabel({ children, ...rest }: TextProps) {
  return (
    <Text size={'2'} medium subtle elementType={'dt'} {...rest}>
      {children}
    </Text>
  )
}

export function PanelItemDescription({ children, ...rest }: TextProps) {
  return (
    <Text size={'2'} elementType={'dd'} className={'max-w-prose'} {...rest}>
      {children}
    </Text>
  )
}

export function PanelItemDescriptionList({
  children,
  ...rest
}: React.ComponentPropsWithoutRef<'div'>) {
  return (
    <div className={'stack space-y-1'} {...rest}>
      {children}
    </div>
  )
}

interface ConsentProps {
  consent: Schemas['Consent']
}

export function Consent(props: ConsentProps) {
  return (
    <Panel
      left={
        <PanelHeader
          heading={'Samtykke'}
          description={
            'Samtykke i journaliseringen er patientens tilladelse til, at sundhedspersonalet må anvende og dele vedkommendes helbredsoplysninger i forbindelse med behandling og i overensstemmelse med lovgivningen.'
          }
        />
      }
      right={
        <>
          <PanelItem>
            <PanelItemLabel>Samtykke</PanelItemLabel>

            <PanelItemDescription>
              Patienten har {props.consent.hasConsented ? 'samtykket' : 'ikke samtykket'}
            </PanelItemDescription>
          </PanelItem>

          <PanelItem>
            <PanelItemLabel>Samtykkedes forklaring</PanelItemLabel>
            <PanelItemDescription>{props.consent.details}</PanelItemDescription>
          </PanelItem>
        </>
      }
    />
  )
}

interface ClassificationProps {
  type: Schemas['ConsultationType']['type']
  classification: Schemas['Classification']
}

export function Classification(props: ClassificationProps) {
  return (
    <Panel
      left={
        <PanelHeader
          heading={'Klassificering'}
          description={
            <div className={'stack space-y-2'}>
              <PanelDescription>
                Klassifikation er en metode til at kategorisere en konsultation.
              </PanelDescription>

              <PanelDescription>
                Hvis du ikke er enig I klassificeringen er det dit eget ansvar, at tilpasse
                journalen, så det passer til typen af konsultationen der har været afholdt med din
                patient. Dette gør det nemmere at identificere og vurdere den.
              </PanelDescription>
            </div>
          }
        />
      }
      right={
        <>
          <PanelItem>
            <PanelItemLabel>Type</PanelItemLabel>

            <PanelItemDescription>
              {props.type === 'first_time' ? 'Første konsultation' : 'Opfølgende konsultation'}
            </PanelItemDescription>
          </PanelItem>

          <PanelItem>
            <PanelItemLabel>Klassificeret mønstre</PanelItemLabel>

            <PanelItemDescriptionList>
              {props.classification.observedPatterns.map((pattern, i) => (
                <PanelItemDescription key={i}>{pattern}</PanelItemDescription>
              ))}
            </PanelItemDescriptionList>
          </PanelItem>
        </>
      }
    />
  )
}
