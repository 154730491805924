import React from 'react'
import { tv } from 'tailwind-variants'

import type { VariantProps } from 'tailwind-variants'

const badgeStyles = tv({
  base: 'w-fit rounded',
  variants: {
    size: {
      '1': 'text-2xs px-2 py-0.5',
      '2': 'px-3 py-1 text-xs',
      '3': 'px-4 py-1.5 text-sm',
    },
    variant: {
      neutral: 'bg-neutral-5 group-hover:bg-neutral-6 text-neutral-12',
      info: 'bg-info-5 group-hover:bg-info-6 text-info-12',
      positive: 'bg-green-5 group-hover:bg-green-6 text-green-12',
      negative: 'bg-red-5 group-hover:bg-red-6 text-red-12',
      yellow: 'bg-yellow-5 group-hover:bg-yellow-6 text-yellow-12',
      accent: 'bg-accent-5 group-hover:bg-accent-6 text-accent-12',
      contrast: 'bg-contrast-5 group-hover:bg-contrast-6 text-contrast-12',
    },
  },
  defaultVariants: {
    size: '2',
    variant: 'neutral',
  },
})

type BadgeVariants = VariantProps<typeof badgeStyles>

export type BadgeProps = React.ComponentPropsWithoutRef<'div'> & BadgeVariants

export function Badge({ children, className, size, variant, ...rest }: BadgeProps) {
  return (
    <div data-variant={variant} className={badgeStyles({ size, variant, className })} {...rest}>
      {children}
    </div>
  )
}
