import type { Schemas } from '@fysioscout/fysioscout-js/type-helpers'
import type { PanelProps } from '@fysioscout/ui/layout/panel'

import { Panel, PanelHeading } from '@fysioscout/ui/layout/panel'
import { Badge } from '@fysioscout/ui/status/badge'

import { PatientScheduledVcTable } from './tables/patient-scheduled-vc-table'

interface ScheduledConsultationsProps extends PanelProps {
  consultations: Schemas['ConsultationResponseDto'][]
}

export function ScheduledConsultations({ consultations, ...rest }: ScheduledConsultationsProps) {
  return (
    <Panel data-testid={'scheduled-consultations'} {...rest}>
      <div className={'hstack items-center'}>
        <PanelHeading>Planlagte videokonsultationer</PanelHeading>
        <Badge variant={'neutral'}>{consultations.length}</Badge>
      </div>

      <PatientScheduledVcTable consultations={consultations} />
    </Panel>
  )
}
