import React from 'react'

import { CancelConsultationDialog } from './cancel-consultation-dialog'
import { CancelConsultationProvider } from './cancel-consultation-provider'

interface CancelConsultationProps {
  children?: React.ReactNode
}

export function CancelConsultation({ children }: CancelConsultationProps) {
  return (
    <CancelConsultationProvider>
      <CancelConsultationDialog />

      {children}
    </CancelConsultationProvider>
  )
}
