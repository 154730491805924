import { useSuspenseQuery } from '@tanstack/react-query'

import { useHypertune } from '@fysioscout/hypertune/hypertune.react'

import { clinicQueries } from '@/api/fysioscout/endpoints/clinic/queries'

/**
 * Checks if the video consultation feature is enabled for the current user and via Hypertune
 * feature flagging.
 *
 * @example
 *   const isDisabled = useShowDisabledNotice()
 *
 * @returns A boolean indicating whether the video consultation feature is enabled.
 */
export function useShowDisabledNotice() {
  const hypertune = useHypertune()
  const { data: subscription } = useSuspenseQuery(clinicQueries.getSubscription())

  const isFeatureFlagEnabled = hypertune.showVideoConsultationDisabledNotice({ fallback: false })
  const isVideoConsultationsEnabled = subscription.video_consultations.is_enabled

  return !isVideoConsultationsEnabled || isFeatureFlagEnabled
}
