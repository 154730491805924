import React from 'react'
import Fuse from 'fuse.js'

import type { Schemas } from '@fysioscout/fysioscout-js/type-helpers'

import { Panel } from '@fysioscout/ui/layout/panel'

import { PatientTable } from '@/features/patients/components/patient-table'
import { PatientsByEmployee } from '@/features/patients/components/patients-by-employee'
import { SEARCH_MIN_CHAR_LENGTH } from '@/features/patients/config/constants'

import { PatientTableContext } from '../contexts/patient-table-context'

interface AcceptedProps {
  searchQuery?: string
  list: Schemas['EmployeePatients'][]
}

const keys = ['name', 'email'] satisfies (keyof Schemas['EmployeePatients']['patients'][number])[]

export function AcceptedPatients({ searchQuery = '', list }: AcceptedProps) {
  const isQueryValid = searchQuery.length >= SEARCH_MIN_CHAR_LENGTH

  const fuse = React.useMemo(() => {
    const patientsWithPractitioner = list.flatMap((employee) =>
      employee.patients.map((patient) => ({
        ...patient,
        practitioner: employee.employee,
      })),
    )

    return new Fuse(patientsWithPractitioner, {
      keys,
      threshold: 0.3,
      minMatchCharLength: SEARCH_MIN_CHAR_LENGTH,
    })
  }, [list])

  const results = isQueryValid
    ? fuse.search(searchQuery, { limit: 50 }).map((result) => result.item)
    : []

  const renderList = () => {
    if (isQueryValid) {
      return (
        <Panel>
          <PatientTable
            aria-label={`Søgeresultater for "${searchQuery}"`}
            patients={results}
            hideColumns={['deletion']}
          />
        </Panel>
      )
    }

    return <PatientsByEmployee data={list} />
  }

  return (
    <PatientTableContext.Provider
      value={{ enabledActions: ['view', 'change-practitioner', 'detach'] }}
    >
      {renderList()}
    </PatientTableContext.Provider>
  )
}
