import { createFileRoute } from '@tanstack/react-router'
import { fallback, zodSearchValidator } from '@tanstack/router-zod-adapter'
import { z } from 'zod'

import { clinicVideoConsultationsQueries } from '@/api/fysioscout/endpoints/clinic-video-consultations/queries'

import { PatientVideoConsultationView } from './-patient-video-consultation-view'

const SearchSchema = z.object({
  status: fallback(z.enum(['scheduled', 'finished']), 'scheduled').default('scheduled'),
})

export const Route = createFileRoute('/_auth/video-consultation/patient/$patientId/')({
  validateSearch: zodSearchValidator(SearchSchema),
  loaderDeps: ({ search: { status } }) => ({ status }),
  loader: async ({ context: { queryClient }, params: { patientId }, deps: { status } }) => {
    const consultations = await queryClient.ensureQueryData(
      clinicVideoConsultationsQueries.byPatientId({
        path: { id: patientId },
        query: { status },
      }),
    )

    return {
      consultations,
    }
  },
  component: PatientVideoConsultationView,
})
